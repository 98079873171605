import secondItemLogo from './assets/section3_2.png';
import thirdItemLogo from './assets/section3_3.png';
import fourthItemLogo from './assets/section3_4.png';
import fifthItemLogo from './assets/section3_5.png';

export const FEATURE_LIST = [
    {
        image: `${fourthItemLogo}`,
        heading: 'MemePad Buyback Program',
        text: '2% of all MWAR transactions are used to automatically buyback MEPAD tokens.'
    },
    {
        image: `${fifthItemLogo}`,
        heading: '1% Liquidity Fee',
        text: 'Each non-whitelisted transaction takes a 1% transaction fee and adds it directly to PancakeSwap Liquidity.'
    },
    {
        image: `${secondItemLogo}`,
        heading: 'Staking Burns Tokens',
        text: 'Staking in any team of the game burns 100% of tokens, creating significant deflation.'
    },
    {
        image: `${thirdItemLogo}`,
        heading: 'Earn Rewards Forever',
        text: 'In return for your burned stake in the game, you own a permanent share of your teams earnings - forever!'
    }
];
