import React, { useState, useRef, useEffect } from 'react';
import cn from 'classnames';

import { isChrome, iOS } from '../../utils/index';
import styles from './Regions.module.scss';

export const Regions = (props) => {
    const { onClick, selectedRegion, onBlur } = props;
    const [hoverRegion, setHoverRegion] = useState('');
    const imageRef = useRef(null);
    const dessertRef = useRef(null);
    const landRef = useRef(null);
    const snowRef = useRef(null);

    useEffect(() => {
        if (selectedRegion === '') {
            if (hoverRegion === 'doge') {
                imageRef.current.appendChild(dessertRef.current);
            } else if (hoverRegion === 'wojak') {
                imageRef.current.appendChild(snowRef.current);
            } else if (hoverRegion === 'pepe') {
                imageRef.current.appendChild(landRef.current);
            }
        }
    }, [hoverRegion]);
    return (
	 <svg
		 version='1.1'
		 id='Layer_1'
		 xmlns='http://www.w3.org/2000/svg'
		 xmlnsXlink='http://www.w3.org/1999/xlink'
		 x='0px'
		 y='0px'
		 viewBox='0 0 668.18 393'
		 xmlSpace='preserve'
		 fill='transparent'
		 style={{ overflow: 'initial' }}>
            <defs>

                <filter id="sofGlow" height="300%" width="300%" x="-75%" y="-75%">
                    <feMorphology operator="dilate" radius="4" in="SourceAlpha" result="thicken"/>
                    <feGaussianBlur in="thicken" stdDeviation="20" result="blurred"/>
                    <feFlood floodColor="rgb(0,186,255)" result="glowColor"/>
                    <feComposite in="glowColor" in2="blurred" operator="in" result="softGlow_colored"/>
                    <feMerge>
                        <feMergeNode in="softGlow_colored"/>
                        <feMergeNode in="SourceGraphic"/>
                    </feMerge>

                </filter>

            </defs>
            <g ref={imageRef}>
                <image width='100%' height='100%' xlinkHref='img/mapbackground.png'
					   transform='matrix(1.21 0 0 1.21 -71 -41)'/>
                <image ref={dessertRef} className={cn({
                    [styles.dessertImage]: hoverRegion === 'doge',
                    [styles.dessertActive]: selectedRegion === 'doge',
                    [styles.deactive]: selectedRegion !== 'doge' && selectedRegion !== '',
                    [styles.unhoverImage]: hoverRegion !== 'doge' && hoverRegion !== ''
                })} width='688' height='747' 
                xlinkHref={selectedRegion !== 'doge' && selectedRegion !== '' && iOS ? 'img/Dessert_gray.png' : 'img/Dessert.png'} 
                style={{ transform: 'all .5s' }}
					   transform='matrix(0.513 0 0 0.513 315.1992 9.6697)'/>
                <image ref={snowRef} className={cn({
                    [styles.snowImage]: hoverRegion === 'wojak',
                    [styles.snowActive]: selectedRegion === 'wojak',
                    [styles.deactive]: selectedRegion !== 'wojak' && selectedRegion !== '',
                    [styles.unhoverImage]: hoverRegion !== 'wojak' && hoverRegion !== ''
                })} width='653' height='740'
                xlinkHref={selectedRegion !== 'wojak' && selectedRegion !== '' && iOS ? 'img/Snow_gray.png' : 'img/Snow.png'} 
					   transform='matrix(0.507 0 0 0.507 -0.0536 16.2609)'/>
                <image ref={landRef} className={cn({
                    [styles.landImage]: hoverRegion === 'pepe',
                    [styles.landActive]: selectedRegion === 'pepe',
                    [styles.deactive]: selectedRegion !== 'pepe' && selectedRegion !== '',
                    [styles.unhoverImage]: hoverRegion !== 'pepe' && hoverRegion !== ''
                })} width='714' height='505'
                xlinkHref={selectedRegion !== 'pepe' && selectedRegion !== '' && iOS ? 'img/Land_gray.png' : 'img/Land.png'} 
					   transform='matrix(0.5146 0 0 0.5146 212.5888 -0.138)'/>
            </g>
            <g>
                <image xlinkHref='/img/pinDoge.svg' className={cn({
                    [styles.pinDoge]: hoverRegion === 'doge',
                    [styles.dogeActive]: selectedRegion === 'doge',
                    [styles.unhoverImage]: hoverRegion !== 'doge' && hoverRegion !== ''
                })} transform='matrix(0.16 0 0 0.16 550 120)'/>
                <image xlinkHref='/img/pinPepe.svg' className={cn({
                    [styles.pinPepe]: hoverRegion === 'pepe',
                    [styles.pepeActive]: selectedRegion === 'pepe',
                    [styles.unhoverImage]: hoverRegion !== 'pepe' && hoverRegion !== ''
                })} transform='matrix(0.16 0 0 0.16 320 0)'/>
                <image xlinkHref='/img/pinWojak.svg' className={cn({
                    [styles.pinWojak]: hoverRegion === 'wojak',
                    [styles.wojakActive]: selectedRegion === 'wojak',
                    [styles.unhoverImage]: hoverRegion !== 'wojak' && hoverRegion !== ''
                })} transform='matrix(0.16 0 0 0.16 95 192)'/>
            </g>
            <g
			 tabIndex={0}
			 onBlur={() => {onBlur();setHoverRegion('');}}
			 onMouseOut={() => setHoverRegion('')}>
                <path onMouseDown={() => {
                    onClick('wojak')
                }} onMouseEnter={() => {
				 if (!isChrome) return;
                    if (selectedRegion === '') {
                        setHoverRegion('wojak');
                    }
                }} d='M74.44,20.28c-1.16,1.02-1.44,1.63-1.42,3.23c0.02,1.25,0.43,2.4,1.03,3.14c1.4,1.58,2.05,2.88,2.53,4.87
	  c0.21,0.95,0.67,1.95,1.02,2.29c0.35,0.35,0.76,1.54,0.97,2.64c0.22,1.25,0.72,2.3,1.47,2.78c0.84,0.59,0.94,0.84,0.46,1.2
	  c-0.78,0.46-0.6,2.22,0.18,1.95c0.29-0.11,0.48-0.41,0.43-0.66c-0.05-0.3,0.23-0.81,0.57-1.16c0.53-0.51,0.73-0.41,0.99,0.64
	  c0.16,0.7,0.23,1.9,0.14,2.76c-0.08,0.8-0.03,1.35,0.17,1.2c0.19-0.1,0.31,1.2,0.34,2.9c-0.02,1.76,0.11,3.76,0.37,4.51
	  c0.31,1.1,0.17,1.4-0.71,1.72c-0.58,0.16-2.32,1.79-3.91,3.58c-2.88,3.26-3.95,4.03-3.39,2.46c0.23-0.71,0.13-0.75-0.84-0.19
	  c-0.58,0.31-1.75,0.53-2.59,0.39c-0.83-0.09-2.05,0.13-2.73,0.5c-1.26,0.72-2.79,0.15-2.22-0.82c0.14-0.25-0.35-0.45-1.04-0.43
	  c-0.98,0.02-1.27,0.22-1.02,0.77c0.31,0.85-0.42,1.06-0.93,0.27c-0.15-0.3-0.74-0.49-1.23-0.48c-0.49,0.01-1.08-0.18-1.23-0.48
	  c-0.2-0.3,0.04-0.35,0.58-0.21c0.79,0.24,0.79,0.19-0.06-0.5c-1.09-0.88-1.98-1.02-1.52-0.28c0.15,0.25-0.04,0.5-0.43,0.51
	  c-0.44,0.01-0.79-0.24-0.79-0.49c0-0.3-0.89-0.49-1.97-0.47c-2.2,0.04-2.63,0.9-0.57,1.16c0.74,0.09-2.1,0.13-6.26,0.15
	  c-4.85-0.02-7.54-0.23-7.5-0.58c0.09-0.3-0.84-0.49-2.21-0.52c-4.6,0.03-3.98-0.69,0.72-0.76c2.64-0.04,5.63-0.19,6.65-0.31
	  c1.12-0.12,2.06,0.02,2.45,0.41c0.5,0.54,0.45,0.59-0.09,0.3c-0.44-0.24-0.59-0.19-0.39,0.11c0.4,0.65,3.3,1.1,3.29,0.55
	  c0-0.2-0.5-0.49-1.09-0.58c-0.64-0.09,1.12-0.27,3.91-0.37c2.79-0.05,5.44,0.06,5.93,0.35c0.49,0.24,2.21,0.62,3.78,0.89
	  c1.57,0.23,3.24,0.5,3.63,0.59c0.39,0.04,0.68-0.11,0.63-0.41c-0.05-0.3,1.22-0.52,3.37-0.56c2.55,0.01,3.57-0.21,3.71-0.71
	  c0.14-0.4-0.01-0.55-0.35-0.35c-1.11,0.77-0.44-0.14,2.11-2.94c2.41-2.55,2.6-2.95,2.28-4.45c-0.53-2.7-1.23-3.44-1.05-1.09
	  c0.07,1.4-0.16,2.31-0.83,3.02c-0.48,0.56-1.73,2.03-2.69,3.2l-1.83,2.14l-5.09,0.13c-3.72,0.06-5.49-0.16-6.62-0.74
	  c-1.28-0.63-3.49-0.75-13.86-0.58c-11.4,0.19-12.43,0.1-13.67-0.78c-0.74-0.49-1.53-0.93-1.83-0.92c-0.24,0-0.99-0.39-1.63-0.88
	  c-3.12-2.41-6.48-1.2-8.66,3.15l-1.19,2.33l2.16,3.28c1.96,2.93,2.55,3.47,3.58,3.35c0.19-0.05,0.3,0.3,0.26,0.8
	  c0.01,0.45,0.31,0.85,0.7,0.84c0.39,0.04,0.69,0.34,0.6,0.74c-0.09,0.35-0.17,1.56-0.2,2.61c-0.02,1.71,0.28,2.2,2.82,4.67
	  c1.59,1.48,3.47,2.9,4.16,3.19l1.28,0.48l-0.09,15.4l-0.09,15.4l-1.31,0.97c-2.18,1.69-4.91,4.9-6.72,7.89
	  c-0.9,1.57-1.86,2.89-2.11,2.99c-0.19,0.1-0.38,0.41-0.38,0.66s-0.76,1.47-1.67,2.79c-1,1.42-1.67,2.89-1.65,3.74
	  c0.01,0.8-0.45,2.67-0.96,4.13c-0.75,2.02-1.01,3.93-1.05,7.84c0,2.81-0.16,5.27-0.3,5.47c-0.19,0.15-0.33,0.86-0.32,1.51
	  c0.01,0.9-0.42,1.41-1.63,1.93c-0.92,0.42-2.47,1.55-3.43,2.56c-1.01,0.97-1.98,1.79-2.12,1.79c-0.49,0.01-2.46,2.4-2.45,3
	  c0,0.3-0.24,0.56-0.53,0.56c-0.64,0.01-3.33,2.81-3.32,3.47c0,0.25-0.42,1.01-0.9,1.72c-1.58,2.13-1.61,3.49-0.12,7.88
	  c0.98,2.89,1.89,4.58,3.18,5.92c2.19,2.17,3.88,3.55,4.42,3.54c0.78-0.01,2.53,2.22,2.9,3.71c0.21,0.8,0.57,1.8,0.87,2.14
	  c0.95,1.24,1.47,9.21,0.79,12.18c-0.37,1.51-0.67,4.22-0.63,6.13c0.04,2.71-0.18,3.77-1.09,5.28c-1.43,2.38-2.09,6.81-0.97,6.34
	  c0.92-0.37,0.98,0.23,0.12,1.4c-0.33,0.51-0.77,0.77-0.97,0.57c-0.55-0.59-1.26,0.97-1.23,2.88c-0.03,0.95-0.3,2.41-0.73,3.27
	  c-0.57,1.26-0.61,1.31-0.39,0.26c0.14-0.7,0.4-2.31,0.58-3.62c0.17-1.31,0.69-2.77,1.12-3.28c0.53-0.66,0.62-1.16,0.32-1.66
	  c-0.25-0.4-0.5-0.54-0.49-0.24c0,0.25-0.24,0.15-0.54-0.24c-0.45-0.64-0.5-0.64-0.29,0.11c0.15,0.45-0.12,1.36-0.65,1.97
	  c-0.58,0.71-0.95,1.92-0.93,3.18c-0.02,10.78,0.15,12.34,1.45,13.77c0.45,0.44,0.8,1.09,0.81,1.34c0,0.3,1.01,2.04,2.27,3.88
	  c1.71,2.58,2.27,3.93,2.29,5.38c0.02,1.1,0.28,2.4,0.59,2.95c0.4,0.8,0.36,1.25-0.21,1.91c-0.43,0.51-0.62,1.06-0.42,1.26
	  c0.25,0.2,0.2,0.35-0.09,0.3c-1.33-0.28-2.1,0.13-2.33,1.14c-0.14,0.65-0.55,2.07-0.98,3.18c-0.98,2.83-0.89,5.38,0.31,6.92
	  c1.7,2.13,3.53,6.16,3.56,7.82c0.01,0.85,0.23,1.95,0.48,2.4c0.25,0.45,0.57,1.9,0.69,3.15c0.17,1.3,0.53,2.65,0.88,3
	  c2.55,2.97,3.25,4.01,4.41,6.5c0.71,1.59,1.52,2.88,1.71,2.88c0.69-0.01,0.46-1.71-0.39-2.9c-0.4-0.6-0.61-1.09-0.46-1.1
	  c0.49-0.01,1.49,1.13,1.89,2.08c0.16,0.5,0.55,0.89,0.85,0.89s0.39,0.19,0.25,0.5c-0.14,0.25-0.09,0.5,0.16,0.5
	  c0.69-0.01,1.06,1.74,0.53,2.65c-0.28,0.61-0.13,1.11,0.62,1.85c0.55,0.54,1,1.29,1.01,1.64c0.01,0.4,0.76,1.49,1.7,2.43
	  c2.89,2.96,4.07,6.5,3.96,11.77c-0.05,2.86,0.05,3.26,1.55,5.14c1.15,1.49,1.69,1.83,1.98,1.32c0.24-0.41,0.03-1-0.57-1.65
	  c-1.25-1.33-1.96-3.28-1.54-4.44c0.28-0.91,0.33-0.91,0.63-0.11c0.2,0.5,0.37,1.5,0.38,2.3c0.02,1,0.32,1.45,1.1,1.64
	  c0.59,0.19,0.94,0.44,0.74,0.64c-0.14,0.2-0.09,0.75,0.22,1.3c0.4,0.7,0.9,0.94,1.73,0.72c0.63-0.11,1.42,0.08,1.72,0.42
	  c0.3,0.4,0.89,0.69,1.28,0.68c0.34-0.01,0.79,0.34,0.94,0.74c0.2,0.55,0.5,0.59,0.89,0.29c0.39-0.36,1.37,0.23,3.16,1.85
	  c2.39,2.17,3.44,3.61,2,2.73c-0.35-0.19-0.39-0.14-0.19,0.25c0.2,0.3,0.64,0.39,0.93,0.19c0.34-0.21,0.25,0.1-0.14,0.6
	  c-0.43,0.56-0.48,0.81-0.09,0.55c0.29-0.21,0.58-0.16,0.59,0.14c0,0.25,0.4,0.39,0.89,0.29c0.44-0.11,0.93-0.22,1.02-0.22
	  c0.15-0.05-0.3-0.6-0.95-1.29l-1.15-1.24l1.76-0.08c0.98-0.07,2.1-0.13,2.44-0.19c0.29-0.05,0.54,0.24,0.45,0.59
	  c-0.04,0.4,0.11,0.55,0.4,0.34c0.24-0.15,1.03,0.08,1.72,0.57c1.24,0.93,1.75,1.93,0.66,1.39c-0.35-0.19-0.24,0,0.25,0.4
	  c0.45,0.39,0.85,1.29,0.86,1.99c0.01,0.7,0.23,1.8,0.48,2.45c0.61,1.44,3.8,4.85,5.98,6.32c1.44,0.98,1.88,1.07,3.04,0.55
	  c1.12-0.42,1.56-0.38,2.16,0.22c0.55,0.49,0.74,0.54,0.74,0.09c-0.01-0.35,0.14-0.5,0.29-0.31c0.15,0.15,0.11,0.65-0.18,1.11
	  c-0.38,0.81-0.48,0.81-0.78-0.04c-0.31-0.8-0.4-0.8-0.79-0.19c-0.67,1.11-0.54,3.12,0.26,4.16c0.7,0.94,1.62,0.32,1.02-0.67
	  c-0.15-0.3,0.04-0.35,0.44-0.21c0.54,0.19,0.78-0.01,0.77-0.51c-0.01-0.5,0.27-1.46,0.65-2.22c0.99-2.17,1.41-3.84,0.78-3.42
	  c-0.29,0.21-0.54,0.06-0.54-0.29c-0.01-0.45-0.15-0.45-0.49,0.11c-0.24,0.41-0.48,0.51-0.49,0.21c-0.01-0.35-0.74-0.59-1.72-0.57
	  c-0.93,0.02-1.56,0.23-1.41,0.47s-0.29,0.26-0.98-0.03c-0.84-0.29-1.28-0.28-1.27,0.02c0,0.3,0.25,0.5,0.6,0.49
	  c0.29,0,0.39,0.14,0.25,0.35c-0.19,0.15-0.79-0.14-1.38-0.73c-0.55-0.64-1.19-0.98-1.39-0.88c-0.19,0.15-0.39-0.04-0.4-0.34
	  c-0.01-0.45,0.48-0.61,1.41-0.47c0.74,0.09,1.38,0.38,1.38,0.63c0,0.2,1.03,0.28,2.35,0.11c1.27-0.22,3.52-0.11,5,0.22
	  c1.47,0.28,3.98,0.69,5.55,0.91c2.06,0.27,3.29,0.75,4.19,1.59c0.75,0.69,2.67,1.86,4.25,2.54c1.63,0.73,3.5,1.7,4.15,2.24
	  c1.04,0.79,1.73,0.87,4.46,0.58c3.62-0.41,6.41,0.05,11.78,1.86c4.53,1.48,5.61,1.46,10.77-0.23c3.41-1.11,4.73-1.33,7.13-1.07
	  c1.62,0.12,3.53,0.29,4.31,0.33c0.74,0.04,1.33,0.23,1.33,0.38c0.01,0.7,3.19,0.7,6.27-0.05c3.66-0.86,4.2-0.87,9.54-0.46
	  c2.99,0.2,4.36,0.08,6.5-0.66c2.97-1,6.25-0.91,8.18,0.32c0.59,0.39,2.66,0.86,4.52,0.98c1.91,0.17,3.68,0.54,4.03,0.79
	  c0.3,0.25,1.77,0.57,3.19,0.7c2.45,0.16,2.79,0.05,5.31-1.84c2.37-1.79,3.15-2.06,6.03-2.31c3.13-0.25,3.42-0.41,4.86-2.14
	  c1.92-2.34,4.3-3.43,7.58-3.49c2.55-0.04,6.34,1.2,6.35,2.05c0,0.2,0.2,0.3,0.39,0.14c0.24-0.15,1.57,0.28,3,0.95
	  c3.16,1.4,3.74,1.44,6.17,0.05c1.07-0.57,3.06-1.2,4.38-1.38c2.2-0.29,2.64-0.14,4.38,1.23c2.08,1.67,1.98,1.62,8.69,1.61l4.65-0.03
	  l2.53,2.07c1.89,1.57,2.64,2.67,3.16,4.36c0.36,1.2,0.92,2.49,1.32,2.79c0.74,0.64,1.81,0.12,1.36-0.67
	  c-0.25-0.35-0.1-0.4,0.29-0.16c0.49,0.29,0.4,0.6-0.37,1.31c-1.21,0.97-1.34,1.58-0.42,1.41c0.98-0.17,2.51,0.56,3.8,1.99
	  c1.39,1.43,2.97,1.76,2.95,0.6c-0.01-0.4,0.19-0.6,0.43-0.46s0.93-0.17,1.56-0.68c0.63-0.51,1.65-1.08,2.18-1.29
	  c0.58-0.21,1.65-0.93,2.32-1.59c1.83-1.79,4.52-1.83,5.19-0.09c0.26,0.7,0.71,1.24,1,1.24c0.29,0,1.05,1.14,1.66,2.53
	  c0.86,1.84,1.66,2.78,3.09,3.61c1.09,0.58,1.93,1.37,1.84,1.73c-0.04,0.35,0.11,0.5,0.35,0.3c0.68-0.41,2.7,3.62,2.43,4.78
	  c-0.37,1.46,0.66,4.45,1.95,5.74c1.05,1.04,1.44,1.08,5.89,0.81c2.64-0.14,5.03-0.48,5.37-0.79c0.29-0.26,1.9-0.58,3.56-0.76
	  s3.22-0.4,3.47-0.56c0.53-0.36,7.06,0.54,7.75,1.03c0.25,0.15,0.8,0.89,1.25,1.63c0.5,0.69,1.5,1.63,2.24,2.02
	  c0.74,0.39,2.07,1.07,2.96,1.51c0.84,0.44,2.51,1.01,3.69,1.19c1.62,0.32,1.77,0.42,0.74,0.49c-1.22,0.07-1.32,0.17-0.62,0.81
	  c0.4,0.39,1.14,0.73,1.68,0.72c0.93-0.02,0.93-0.12,0.24-3.16c-2.58-10.94-3.03-14.6-2.87-22.88c0.27-13.1,1.53-29.27,2.53-33.9
	  c1.65-7.1,2.02-8.01,4.47-10.86c4.85-5.5,4.99-5.95,4.87-13.37c-0.09-5.77-0.26-6.97-1.54-10.51c-1.95-5.54-2.26-6.43-4.05-10.97
	  c-1.38-3.59-1.54-4.59-1.63-10.01c-0.1-3.41,0.09-6.62,0.37-7.43c0.56-1.46,0.23-4.12-1.38-9.36c-2.96-10.03-4.21-12.12-9.26-15.45
	  c-2.57-1.76-15.73-9.72-17.9-10.89c-5.24-2.82-9.06-6.12-16.88-14.47c-3.09-3.31-5.51-4.62-12.65-6.97c-3.2-1-4.14-1.64-7.82-5.09
	  l-4.23-3.94l-0.51-4.26c-0.34-2.95-0.97-5.2-1.98-7.24c-0.81-1.64-1.93-3.98-2.53-5.18c-2.23-4.63-1.18-9.11,3.93-16.82
	  c3.25-4.87,3.49-5.22,5.29-9.07l1.37-2.88l-1.11-1.94c-0.6-1.04-2.4-3.12-3.99-4.6c-4.77-4.39-6.72-6.46-6.73-7.26
	  c-0.01-0.4,1.38-2.28,3.11-4.21c1.68-1.93,3.55-4.57,4.06-5.89c0.57-1.36,1.18-2.88,1.41-3.43c2.35-5.81,3.72-11.85,3.65-16.21
	  c-0.05-3.31-0.26-4.21-1.88-7.44c-1.72-3.53-4.87-7.29-7.94-9.45c-15.21-10.99-23.36-18.48-26.25-24.3
	  c-0.51-0.94-1.11-2.09-1.36-2.49c-0.3-0.4-1.67-3.18-3.04-6.12l-2.59-5.43l-0.2-6.32c-0.15-6.12,0.1-8.58,1.63-13.92
	  c0.55-2.07,0.23-3.87-0.81-4.5c-0.35-0.19-2.16-0.32-4.07-0.28c-2.59,0.09-3.67,0.31-4.05,0.87c-0.29,0.46-0.77,0.82-1.06,0.82
	  c-0.49,0.01-3.41,1.31-5.93,2.61c-2.09,1.04-3.8,1.07-7.1-0.03c-2.46-0.86-4.13-1.09-5.89-0.91c-1.61,0.18-3.77-0.09-6.28-0.8
	  l-3.84-1.04l-1.06,1.12c-0.72,0.66-1.74,1.08-2.92,1.1c-3.38,0.06-9.62,1.81-9.6,2.72c0,0.2-0.67,0.91-1.54,1.53
	  c-1.26,0.97-1.5,1.03-1.22,0.27c0.19-0.5,0.13-0.75-0.2-0.55c-0.24,0.2-0.83,0.06-1.23-0.28c-0.6-0.49-0.64-0.44-0.34,0.36
	  c0.16,0.5,0.56,1.09,0.85,1.29c0.35,0.25,0.25,0.4-0.19,0.4c-1.08-0.03-2.22-1.02-3.13-2.81c-1.21-2.29-4.41-3.39-7.73-2.58
	  c-3.71,0.86-5.35,1.94-5.67,3.7c-0.13,0.8-0.46,1.61-0.75,1.82c-0.29,0.21-0.39,0.01-0.2-0.5c0.56-1.56-0.41-1.25-2.29,0.74
	  c-1.78,1.78-3.39,2.56-5.88,2.81c-0.54,0.06-1.61,0.13-2.35,0.19c-0.78,0.06-1.27,0.27-1.17,0.47c0.25,0.4-0.82,0.62-3.51,0.76
	  c-0.83,0.06-0.98,0.17-0.48,0.46c0.5,0.34,0.35,0.55-0.67,0.96c-0.73,0.31-1.31,0.77-1.31,1.02c0,0.3-0.34,0.41-0.73,0.26
	  c-0.49-0.19-0.64,0.01-0.48,0.81c0.16,0.65-0.08,1.16-0.61,1.36c-0.44,0.21-0.97,0.62-1.16,0.97c-0.24,0.36-1.41,0.78-2.73,1
	  c-1.86,0.28-2.79,0.15-4.86-0.82c-1.43-0.63-2.42-1.36-2.23-1.57c0.19-0.25,0.04-0.25-0.25-0.05c-0.34,0.21-1.42-0.03-2.41-0.46
	  c-1.04-0.48-2.66-0.81-3.73-0.79c-1.03,0.02-1.86-0.12-1.87-0.32c0-0.25-0.45-0.29-0.98-0.13c-0.54,0.16-2.01-0.02-3.29-0.45
	  c-1.33-0.38-2.95-0.85-3.69-0.99c-0.79-0.14-1.33-0.48-1.33-0.68c0.13-0.91-0.12-1.6-0.61-1.6c-0.24,0-0.34,0.26-0.14,0.6
	  c0.2,0.35,0.15,0.4-0.19,0.2c-0.3-0.2-0.4-0.7-0.26-1.15c0.19-0.55,0.14-0.7-0.3-0.45c-0.29,0.21-0.49,0.16-0.44-0.09
	  c0.09-0.3-0.06-0.55-0.35-0.6c-0.69-0.14-1.98-1.07-3.91-2.8c-0.89-0.84-2.18-1.67-2.82-1.86c-1.62-0.47-2.47-1.21-2.09-1.87
	  c0.14-0.25,0.09-0.4-0.15-0.25c-0.24,0.15-1.18-0.28-2.07-1.07c-1.24-1.03-1.49-1.43-0.91-1.64c0.44-0.21,1.23,0.18,1.87,0.87
	  c0.65,0.69,1.39,1.23,1.68,1.23c1.08-0.02,8.17,5.53,7.93,6.19c-0.14,0.4,0.01,0.55,0.45,0.39c0.34-0.16,1.23,0.48,2.08,1.47
	  c0.9,1.19,1.79,1.78,2.62,1.76c0.69-0.01,2.21,0.31,3.39,0.75c1.23,0.38,3.39,0.65,4.81,0.57c2.05-0.13,2.94,0.05,4.08,0.89
	  c0.79,0.59,1.98,1.07,2.66,1.06c0.69-0.01,1.62,0.42,2.12,0.92c0.5,0.54,1.93,1.07,3.15,1.25c2.75,0.36,4.93-0.63,5.98-2.76
	  c0.38-0.76,1.15-1.82,1.68-2.33c1.01-0.97,5.3-2.29,9.21-2.81c1.12-0.17,2.05-0.54,2.04-0.84c0-0.3,0.14-0.35,0.34-0.21
	  c0.15,0.2,0.88-0.01,1.51-0.48c1.11-0.77,1.21-0.77,0.93-0.02c-0.19,0.55-0.13,0.75,0.2,0.55c0.29-0.21,0.38-0.66,0.23-1.01
	  c-0.1-0.35,0.03-0.9,0.32-1.26c0.34-0.36-0.05-0.25-0.78,0.26c-0.73,0.51-1.46,0.78-1.66,0.58c-0.2-0.15-0.78,0.01-1.36,0.42
	  c-0.58,0.41-2.48,0.89-4.19,1.12c-4.01,0.47-7.51,2.08-8.84,4.11c-0.57,0.86-1.34,1.98-1.77,2.44c-0.96,1.22-3.95,1.22-5.97,0
	  c-0.89-0.54-2.12-0.97-2.71-0.96c-0.59,0.01-1.18-0.18-1.38-0.43c-0.6-0.99-3.46-2-5.61-1.96c-1.22-0.03-3.78-0.39-5.69-0.81
	  c-2.95-0.7-3.79-1.19-5.93-3.26c-3.08-3.01-5.96-5.27-7.29-5.8c-0.54-0.19-1.83-1.02-2.87-1.81C79.16,18.55,76.52,18.49,74.44,20.28
	  z'/>
                <path onMouseDown={() => {
                    onClick('pepe');
                    setHoverRegion('');
                }} onMouseEnter={() => {
				 if (!isChrome) return;
                    if (selectedRegion === '') {
                        setHoverRegion('pepe');
                    }
                }} d='M315.32,3c-4.19,0.81-8.62,2.02-9.48,2.52c-0.76,0.4-1.11,0.3-1.66-0.4c-0.35-0.5-0.71-1.06-0.71-1.26
	  c0-0.15-1.31-0.25-2.87-0.15c-1.61,0.05-4.64,0.2-6.71,0.3c-3.38,0.2-3.78,0.3-3.63,1.16c0.15,0.76-0.05,0.91-0.86,0.66
	  c-0.61-0.15-0.96,0-0.91,0.3c0.1,0.61-6.2,2.57-10.99,3.43c-4.79,0.86-11.35,1.11-11.35,0.5c0-0.3-0.45-0.55-1.01-0.55
	  c-0.61,0-1.11-0.45-1.26-1.16c-0.15-0.61-1.11-1.66-2.12-2.37c-1.46-1.01-2.42-1.26-4.69-1.21c-1.56,0.05-3.38,0.35-4.03,0.76
	  c-0.71,0.35-1.46,0.71-1.77,0.71c-0.25,0-0.45,0.4-0.45,0.86c0.05,0.55-0.2,0.71-0.71,0.55c-0.45-0.2-0.66-0.1-0.45,0.2
	  c0.5,0.81-0.86,1.16-4.34,1.21c-4.03,0-10.09,2.87-11.8,5.55c-2.02,3.08-2.02,3.08-4.09,1.82c-1.01-0.61-2.98-1.41-4.29-1.77
	  c-2.22-0.55-2.62-0.55-4.14,0.4c-0.91,0.55-1.97,1.01-2.42,1.01c-0.4,0-1.26,0.4-1.92,0.91c-3.83,3.13-5.55,24.56-2.42,29.86
	  c0.25,0.4,0.71,1.31,1.01,2.02c3.08,7.36,7.31,14.42,11.05,18.46c2.12,2.27,13.77,11.7,18.36,14.83c5.7,3.83,9.83,9.48,10.59,14.37
	  c0.4,2.62-0.25,6.91-1.92,13.01c-1.97,7.26-3.33,9.83-6.86,13.11c-3.58,3.33-4.09,4.84-2.77,8.17c0.45,1.26,1.01,2.27,1.21,2.27
	  c0.76,0,9.13,8.37,9.13,9.08c-0.05,0.4-0.61,1.66-1.26,2.77c-0.66,1.11-1.66,2.93-2.27,4.03c-0.61,1.11-2.17,3.48-3.48,5.3
	  c-2.42,3.33-4.59,8.98-4.59,11.85c0,2.27,1.26,6.56,2.98,10.04c1.82,3.73,1.92,4.09,2.57,8.78c0.5,3.48,1.46,6.15,2.72,7.41
	  c2.02,2.02,7.67,6.81,8.02,6.81c0.4,0,3.18,1.01,9.94,3.68c1.66,0.71,3.43,1.87,4.54,3.13c7.41,8.32,13.01,13.21,19.97,17.3
	  c8.27,4.89,18.76,11.6,19.92,12.66c2.32,2.22,5.19,9.53,6.2,15.84c0.35,2.17,0.91,4.19,1.21,4.49c0.45,0.45,1.46,0.45,4.14,0
	  c1.97-0.35,4.84-0.61,6.46-0.61c2.72-0.05,4.64-0.81,4.64-1.87c0-0.71,2.87-3.18,3.68-3.18c0.35,0,1.41-0.66,2.32-1.46
	  c1.97-1.77,3.63-2.02,16.44-2.17l9.08-0.15l7.31,3.63c13.57,6.76,17.05,7.51,26.98,5.95c9.83-1.51,9.53-1.36,11.2-5.25
	  c2.72-6.51,5.4-9.73,9.08-11.15c3.43-1.26,6.71-1.26,10.24,0.05c5.95,2.17,6.41,2.22,8.22,0.81c0.91-0.71,3.13-2.77,4.89-4.59
	  l3.28-3.33v-4.44c0-4.09,0.1-4.59,1.36-6.3c0.81-0.96,1.41-2.02,1.41-2.27c0-0.25,0.35-0.76,0.76-1.11
	  c0.55-0.45,0.76-1.66,0.76-4.24c0-3.83,0.55-5.14,3.38-8.17c2.98-3.18,5.09-6.61,6.46-10.54c0.76-2.22,2.42-6.1,3.68-8.57
	  c1.26-2.52,2.72-5.4,3.23-6.41c0.96-1.97,1.21-5.19,0.45-5.65c-0.25-0.15-0.55-1.01-0.71-1.92c-0.1-0.91-0.45-2.42-0.76-3.28
	  c-0.5-1.51-0.4-1.82,1.16-3.68c0.91-1.06,2.62-3.73,3.78-5.85c2.02-3.68,2.12-4.03,1.87-6.96c-0.15-1.71-0.55-4.34-0.81-5.85
	  c-0.45-2.47-0.4-2.82,0.5-3.83c1.56-1.66,1.97-3.98,1.97-10.79c0-3.48,0.25-7.21,0.55-8.32c0.61-2.17,2.77-5.3,3.68-5.3
	  c0.35,0,1.87-1.11,3.38-2.42c2.93-2.62,10.94-6.81,15.23-7.97c1.97-0.5,4.29-0.61,8.93-0.4c5.3,0.3,6.61,0.2,7.92-0.45
	  c2.12-1.11,3.68-3.18,3.68-4.89c0-1.51,0.91-2.02,3.88-2.02c1.36,0,2.12-0.5,4.03-2.62c5.4-5.9,5.4-5.85,8.17-6.25
	  c2.27-0.3,2.82-0.61,3.63-1.92c0.5-0.86,0.96-2.17,0.96-2.87c0-1.21,0.3-1.41,2.93-2.12c1.56-0.4,3.63-0.91,4.54-1.11
	  c3.23-0.66,8.27-5.25,10.29-9.33c0.76-1.51,2.42-3.93,3.68-5.3c1.31-1.41,3.23-3.53,4.24-4.79c1.01-1.26,1.92-2.37,2.07-2.52
	  s1.06-1.21,1.97-2.42c1.61-2.07,1.66-2.32,1.41-5.3c-0.3-3.78-0.96-5.04-3.88-7.72c-2.82-2.57-4.19-3.33-5.35-2.87
	  c-0.55,0.2-0.76,0.15-0.55-0.2c0.25-0.4-0.3-0.4-1.77-0.1c-1.11,0.3-2.42,0.45-2.87,0.4c-0.45-0.1-1.66,0.25-2.77,0.71
	  c-1.06,0.45-2.12,0.86-2.27,0.86c-0.2,0-1.26,0.66-2.37,1.51c-1.06,0.81-2.22,1.51-2.57,1.51c-0.86,0-1.36-2.02-0.71-2.67
	  c0.86-0.86,0.71-1.16-1.46-3.48c-1.71-1.82-2.12-2.07-2.57-1.41c-0.45,0.66-0.5,0.66-0.25-0.1c0.15-0.45-0.15-1.31-0.61-1.87
	  c-0.96-1.06-10.04-6.61-10.89-6.61c-0.3,0-1.11-0.45-1.82-1.01c-0.71-0.55-1.46-1.01-1.77-1.01c-0.25,0-1.82-1.26-3.48-2.72
	  c-2.98-2.77-4.39-3.33-8.47-3.33c-1.06,0-1.92,0.15-1.92,0.4c0,0.2,0.86,0.4,1.92,0.45c4.29,0.2,6.35,0.96,8.12,2.87
	  c1.56,1.66,0.81,1.71-0.96,0.05c-1.31-1.26-2.07-1.41-9.48-1.97c-2.17-0.15-3.33-0.05-3.73,0.4c-0.35,0.35-1.06,0.55-1.56,0.4
	  c-0.55-0.15-1.11-0.05-1.31,0.25c-0.15,0.25-0.86,0.35-1.51,0.15c-1.01-0.2-0.4-0.5,2.62-1.26c2.12-0.5,3.88-1.16,3.88-1.36
	  c0-0.55-0.96-0.45-3.28,0.35c-1.11,0.4-2.47,0.76-3.08,0.76c-0.55,0-1.46,0.35-2.02,0.81c-0.5,0.4-0.66,0.61-0.3,0.45
	  c0.5-0.25,0.66-0.1,0.5,0.55c-0.2,1.26-2.62,2.37-5.3,2.52c-2.17,0.15-4.94,1.77-4.29,2.47c0.2,0.25,0.05,0.25-0.3,0.05
	  c-0.4-0.25-1.11,0-1.71,0.55c-0.61,0.5-0.91,0.61-0.71,0.25c0.35-0.55,0.2-0.55-0.61-0.05c-0.61,0.35-1.26,0.5-1.46,0.3
	  c-0.2-0.25-0.05-0.61,0.35-0.86c0.55-0.35,0.05-0.5-1.51-0.45c-1.71,0-2.02,0.1-1.26,0.45c0.55,0.25,0.81,0.45,0.5,0.5
	  c-0.25,0.05-0.05,0.3,0.5,0.55c0.86,0.4,0.81,0.45-0.25,0.15c-0.71-0.15-1.82-0.4-2.52-0.5c-1.21-0.25-1.21-0.25-0.3,0.5
	  c0.66,0.55,0.76,0.81,0.3,0.81c-0.35,0-1.11-0.35-1.61-0.86c-0.86-0.76-0.91-0.76-0.91,0c0,0.5-0.25,0.86-0.55,0.86
	  c-0.25,0-0.35-0.2-0.2-0.5c0.35-0.55-2.87-0.05-3.28,0.5c-0.35,0.55-4.54,0.25-6.05-0.4c-0.71-0.3-1.87-0.66-2.62-0.81
	  c-0.81-0.2-1.41-0.66-1.41-1.01c0-0.61,0.2-0.61,1.41-0.05c1.97,0.91,4.29,1.41,3.88,0.81c-0.15-0.3-1.11-0.66-2.17-0.86
	  s-2.37-0.91-3.03-1.66c-0.71-0.76-1.06-0.96-0.96-0.5c0.25,0.76,0.15,0.76-1.26,0.05c-1.82-0.91-7.51-1.01-9.68-0.15
	  c-0.86,0.35-1.66,0.4-2.02,0.15c-0.3-0.25-1.21-0.66-2.07-0.86l-1.51-0.45l2.02-0.3l2.02-0.3l-2.67-0.25
	  c-2.62-0.2-2.67-0.2-2.37,1.06c0.3,1.16,0.15,1.11-1.36-0.55c-2.22-2.42-3.63-3.23-7.06-3.98c-2.32-0.5-3.48-0.5-6.05,0
	  c-1.77,0.3-3.48,0.86-3.83,1.11c-0.45,0.4-0.55,0.35-0.3-0.3c0.15-0.45,0.5-0.81,0.81-0.81c0.25,0,0.81-0.35,1.21-0.76
	  c1.31-1.26-0.15-0.86-1.77,0.55c-1.61,1.31-3.08,1.36-3.08,0c0-0.35-0.35-0.86-0.81-1.01c-0.76-0.3-0.76-0.2,0,0.91
	  c1.01,1.56,1.06,2.37,0.1,2.02c-0.4-0.15-1.01-0.96-1.31-1.77c-0.91-2.12-2.27-2.87-5.65-3.28c-2.22-0.25-2.93-0.55-2.93-1.11
	  c0-0.76-2.87-3.63-3.63-3.63c-0.2,0-0.25,0.5-0.15,1.11l0.2,1.16l-0.86-1.06c-1.16-1.46-2.72-2.22-4.64-2.22
	  c-0.86,0-2.37-0.35-3.38-0.76c-2.17-0.91-5.4-0.96-8.52-0.15c-2.02,0.55-2.37,0.55-2.77-0.2c-0.35-0.66-1.31-0.81-5.35-0.76
	  c-2.72,0.05-4.14,0.2-3.18,0.35c1.36,0.2,1.56,0.35,0.86,0.66c-0.5,0.2-1.31,0.35-1.77,0.35c-0.4,0-1.16,0.35-1.66,0.81
	  s-2.02,0.86-3.58,0.96c-1.46,0.1-3.68,0.4-4.84,0.66c-1.92,0.45-2.32,0.4-3.18-0.45c-0.5-0.5-0.96-0.76-0.96-0.45
	  c0,0.25-1.41,0.4-3.38,0.3c-4.03-0.25-6.71-0.76-6.71-1.31c0-0.4-3.68-1.16-6.05-1.31c-2.52-0.1-2.82-0.2-2.87-1.26
	  c-0.1-1.36-0.3-1.56-1.36-1.16c-0.45,0.15-0.66,0.5-0.5,0.81c0.15,0.25-0.15,0.2-0.66-0.15c-0.55-0.3-1.01-0.91-1.01-1.26
	  c0-0.81,0.66-0.91,1.11-0.2c0.15,0.25,0.55,0.5,0.91,0.5c0.61,0-2.62-2.98-3.28-3.03c-0.2,0-0.15,0.61,0.1,1.36l0.5,1.41l-1.21-1.36
	  c-0.86-0.96-2.17-1.51-5.14-2.17c-2.22-0.5-4.84-1.31-5.95-1.87c-1.26-0.66-3.48-1.06-6.41-1.26c-3.63-0.25-4.79-0.5-6.1-1.46
	  C320.87,2.4,319.05,2.24,315.32,3z'/>
                <path onMouseDown={() => {
                    onClick('doge')
                }} onMouseEnter={() => {
				 if (!isChrome) return;
                    if (selectedRegion === '') {
                        setHoverRegion('doge');
                    }
                }} d='M590,13.74c-0.36,0.41-0.82,0.67-0.97,0.51c-0.2-0.1-1.18,0.67-2.15,1.74c-1.23,1.28-2.61,2.15-4.25,2.61
  c-1.38,0.36-3.02,1.18-3.68,1.79c-1.28,1.18-1.69,2.15-0.61,1.48c0.36-0.2,0.41-0.15,0.2,0.2c-0.2,0.36-0.67,0.61-1.07,0.61
  c-0.87,0-4.96,5.58-4.55,6.19c0.15,0.26-0.05,0.36-0.41,0.2c-1.18-0.41-2.61,2.25-2.76,5.17c-0.1,1.53-0.26,2.97-0.41,3.22
  c-0.15,0.26-0.15,2.25,0.05,4.45c0.31,4.04,0.36,4.04,3.07,6.91c4.2,4.45,3.84,6.7-2.2,13.25c-4.71,5.07-8.9,10.49-8.9,11.51
  c0,0.72-4.86,5.47-6.4,6.24c-0.56,0.31-2.51,0.82-4.35,1.18c-5.37,1.02-6.65,1.94-6.65,5.01c0,2.05-0.46,2.4-3.63,3.12
  c-2.61,0.56-3.27,0.97-6.5,4.35c-3.48,3.58-3.79,3.73-6.6,4.09c-3.58,0.41-4.25,0.92-4.25,3.33c0,3.73-1.07,4.14-11.36,4.14h-8.39
  l-6.55,3.27c-3.58,1.84-6.91,3.79-7.32,4.35c-0.41,0.61-1.84,1.84-3.17,2.76c-2.81,1.89-5.42,4.71-6.14,6.65
  c-0.31,0.77-0.77,5.22-1.02,9.87c-0.51,7.93-0.61,8.54-1.84,9.98c-1.48,1.79-1.53,2.51-0.51,7.11c1.18,5.37,1.02,7.21-0.92,10.03
  c-4.76,7.01-5.58,8.75-5.32,11.1c0.15,1.23,0.67,3.43,1.18,4.91l0.82,2.66l-2.81,6.24c-1.59,3.43-3.58,8.19-4.5,10.59
  c-1.89,5.12-2.71,6.34-6.8,10.64l-3.07,3.17l-0.31,3.99c-0.26,2.97-0.72,4.76-2.05,7.42c-0.92,1.89-1.89,3.58-2.1,3.73
  c-0.26,0.15-0.46,2.25-0.46,4.71v4.5l-2.97,2.81c-5.32,5.07-4.5,4.81-9,3.02c-3.12-1.23-4.66-1.53-7.78-1.53
  c-5.83,0-8.19,1.23-11.92,6.14c-1.64,2.15-3.48,5.22-4.14,6.8c-1.38,3.27-0.56,2.97-10.9,4.45l-7.06,1.02l-3.12-1.02
  c-1.74-0.56-3.43-1.02-3.79-1.02c-0.31,0-4.09-1.74-8.34-3.84c-10.23-5.07-11.46-5.37-20.26-5.17c-3.94,0.1-9,0.2-11.26,0.26
  c-3.99,0.05-4.14,0.1-5.01,1.53c-0.51,0.87-1.38,1.69-2.05,1.89c-1.94,0.61-4.81,2.76-5.37,4.04c-0.51,1.07-0.92,1.28-3.02,1.28
  c-4.2,0.05-8.9,0.92-9.98,1.89c-1.94,1.74-2.61,4.2-2.61,9.46c0.05,4.6,0.2,5.47,2.71,12.53c4.04,11.61,4.71,14.22,4.76,19.34
  c0.1,5.17-0.31,6.14-4.6,11.15c-1.28,1.48-2.76,3.63-3.27,4.76c-1.07,2.35-2.66,9.82-3.53,16.63c-1.48,11.26-2.51,37.04-1.53,38.01
  c0.26,0.26,0.51,1.48,0.51,2.66c0,2.2,1.13,7.73,2.05,10.18c0.26,0.72,0.87,1.28,1.38,1.28c0.72,0,0.77,0.1,0.26,0.46
  c-1.07,0.67,0.67,2.1,2.51,2.1c1.89,0,3.68-0.87,3.22-1.59c-0.15-0.31,0.15-0.26,0.77,0.15c0.61,0.36,1.23,1.43,1.38,2.35
  c0.26,1.28,0.82,1.94,2.46,2.92c1.84,1.07,2.66,1.23,6.14,1.07c3.27-0.1,4.2-0.31,4.86-1.13c0.67-0.82,1.89-1.18,5.68-1.59
  c2.66-0.31,5.99-0.41,7.37-0.31c1.38,0.15,3.38,0,4.45-0.36c1.94-0.67,2-0.67,3.99,1.38c2.35,2.46,6.34,3.99,8.85,3.48
  c0.92-0.2,2.25-1.02,2.97-1.84c0.82-1.02,1.89-1.59,3.07-1.74c2.05-0.26,6.96,1.02,8.03,2.1c0.51,0.51,1.89,0.72,4.5,0.72
  c4.09,0,4.14,0,7.06-4.55c0.61-0.97,1.48-1.94,1.89-2.15c0.92-0.51,3.99-1.74,5.37-2.2c0.72-0.26,0.82-0.46,0.36-0.77
  c-0.51-0.31-0.46-0.56,0.2-1.13c0.77-0.61,0.87-0.61,0.46,0c-0.26,0.51-0.2,0.61,0.2,0.36c0.31-0.2,0.46-0.56,0.31-0.82
  c-0.15-0.26,0.05-0.72,0.41-1.13c0.36-0.36,1.18-1.48,1.79-2.51c0.61-1.02,1.89-2.56,2.87-3.38c2.1-1.79,2.4-2.97,2.66-11.77
  c0.26-9.06,0.31-9.36,1.84-11.66c0.77-1.13,1.53-2.66,1.69-3.43c0.2-0.72,1.07-2.25,2-3.33c0.92-1.07,1.94-2.46,2.3-3.12
  c0.36-0.61,0.82-1.02,1.13-0.87c0.26,0.2,0.46-0.31,0.46-1.13c0.05-1.89,1.94-5.17,3.58-6.09c1.64-0.92,4.25-1.94,4.76-1.84
  c0.2,0.05,0.36-0.1,0.36-0.41c0-0.56,2.71-2.05,3.79-2.05c0.46,0,1.38,0.92,2.05,2c0.97,1.53,1.89,2.2,3.79,2.81
  c1.38,0.46,3.43,1.53,4.5,2.35c2.71,2.05,4.3,1.94,10.69-0.56c2.97-1.18,5.73-1.94,6.24-1.79c0.51,0.2,1.28,1.07,1.69,1.94
  c0.82,1.74,1.48,2.25,3.63,2.66c1.07,0.26,1.43,0.67,1.69,2.2c0.61,3.84,4.6,6.65,8.34,5.99c1.02-0.2,2.92,0.15,5.42,1.02
  c2.15,0.72,4.76,1.33,5.83,1.33c1.79,0,7.16-1.28,9.52-2.3c0.72-0.31,0.87-0.1,0.87,0.92c0,0.72,0.15,1.38,0.41,1.48
  c0.2,0.1,1.13,1.74,2.1,3.63c1.89,3.79,3.73,5.53,7.21,6.75c1.89,0.67,3.53,0.72,11.36,0.26l9.16-0.51l3.94-2.71
  c3.99-2.76,6.96-6.24,9.41-11c0.61-1.18,1.33-2.2,1.64-2.2c0.31-0.05,1.28-0.15,2.1-0.26c2.92-0.31,3.58-0.36,10.54-0.2
  c6.65,0.15,7.11,0.1,9.72-1.23c3.53-1.74,6.45-4.55,8.34-8.03c0.82-1.53,1.69-3.02,1.94-3.38c0.46-0.56,6.5-4.2,7.83-4.71
  c1.13-0.41,2.3-1.79,2.3-2.66c0-2.1,1.28-3.53,4.55-5.27c1.84-0.97,4.14-2.56,5.12-3.53c1.79-1.89,1.79-1.94,2.15-12.38
  c0-0.56-0.15-2.25-0.36-3.68c-0.31-2.56-0.26-2.71,0.77-2.71c1.28,0,4.14-2.61,4.14-3.73c0-0.41,1.23-2,2.71-3.48
  c1.43-1.48,3.38-3.99,4.2-5.58c1.69-3.22,8.08-9.52,9.06-8.9c0.36,0.2,0.41,0.15,0.2-0.26c-0.2-0.31,0-0.72,0.56-0.92
  c0.51-0.15,2-1.07,3.38-2.05c1.64-1.13,2.97-1.69,4.2-1.69c2.4,0,5.63-2.1,6.7-4.35c0.92-2,1.28-2.3,3.48-3.07
  c1.94-0.67,2.4-2.4,2-7.26c-0.31-3.53-0.36-3.79-2-4.76c-1.43-0.82-1.74-1.28-1.74-2.66c0-0.97-0.31-2.97-0.72-4.45
  c-0.41-1.69-0.56-3.33-0.31-4.14c0.46-1.53,0.1-3.94-0.56-4.4c-1.23-0.72,0.36-3.79,2-3.79c0.67,0,3.17-2.4,3.17-3.02
  c0-0.2,0.46-0.82,1.02-1.33c1.48-1.33,1.28-3.38-0.56-5.42c-1.18-1.33-1.48-2.05-1.23-3.02c0.15-0.72,0.46-1.89,0.61-2.66
  c0.15-0.77,0.56-1.69,0.97-2c0.41-0.31,0.72-0.87,0.72-1.28c0-0.36,0.36-1.02,0.82-1.48c2.1-2.1,3.27-4.45,3.27-6.55
  c0-1.89-0.36-2.56-2.51-5.01c-1.94-2.2-2.56-3.27-2.81-5.22c-0.41-2.61-0.46-2.66-4.91-7.52c-7.16-7.78-5.68-6.96-16.78-9.16
  c-2.97-0.61-6.75-3.22-6.75-4.66c0-0.51,0.46-1.23,1.07-1.59c0.67-0.46,1.13-1.48,1.33-3.02c0.31-2.25,1.13-3.58,2.15-3.58
  c0.87,0,1.64-1.94,1.28-3.33c-0.72-2.97,1.64-6.4,4.45-6.4c2.51,0,6.5-2.05,6.86-3.53c0.31-1.23,2.05-3.12,2.92-3.12
  c0.2,0,1.28-0.82,2.4-1.79c1.07-0.97,2.66-2.05,3.48-2.4c1.94-0.82,4.25-3.53,4.25-5.01c0-0.67,0.92-2.15,2.05-3.38
  s2.05-2.46,2.05-2.76c0-0.26,0.36-0.51,0.77-0.51c0.77,0,4.3-4.4,4.35-5.42c0-0.31,0.36-0.87,0.77-1.23
  c0.41-0.36,0.77-0.97,0.77-1.38c0-0.36,0.41-1.33,0.87-2.05c0.87-1.33,1.33-2.3,2.71-6.29c0.77-2.05,2.87-6.8,3.84-8.75
  c1.53-2.92,1.43-8.24-0.26-11.97c-1.64-3.58-3.07-7.98-3.07-9.31c0-0.87-0.36-2.56-0.77-3.73c-0.41-1.18-0.77-3.58-0.77-5.32
  c0-2.87,0.15-3.27,1.53-4.4c0.82-0.72,1.48-1.59,1.53-1.94c0-0.31,0.46-1.48,1.02-2.51c0.67-1.28,1.02-2.92,1.02-4.71
  c0-1.89,0.41-3.63,1.28-5.47c1.28-2.66,1.28-2.66,2.92-2.2c0.87,0.26,1.74,0.31,1.89,0.2c0.31-0.36-0.2-0.82-1.13-0.97
  c-0.41-0.05-1.59-0.67-2.61-1.33c-1.94-1.33-2.3-2.97-0.56-2.66c0.82,0.15,0.87,0.05,0.36-0.56c-0.36-0.41-1.07-0.67-1.53-0.51
  c-0.92,0.2-3.07-1.64-2.56-2.2c0.15-0.15-0.26-0.82-0.97-1.53c-0.67-0.67-1.07-1.48-0.92-1.74c0.15-0.31,0.1-0.51-0.2-0.51
  c-0.97,0-1.53-1.84-1.07-3.73c0.2-1.02,0.26-1.89,0.05-1.89c-0.15,0-0.61-0.72-1.02-1.59c-0.36-0.87-1.74-2.25-3.07-3.07
  c-2.25-1.48-3.02-2.46-3.07-4.04c-0.05-0.67,0-0.67,0.51,0c0.41,0.61,0.51,0.61,0.36,0.05c-0.1-0.41-1.02-1.18-2-1.69
  c-1.43-0.77-2.4-0.87-5.58-0.61c-2.1,0.2-4.14,0.56-4.5,0.87c-0.46,0.36-0.56,0.26-0.26-0.46c0.31-0.87,0.26-0.87-0.51-0.26
  c-0.61,0.51-0.72,0.92-0.36,1.59c0.56,1.07-0.2,1.53-2.71,1.53c-0.92,0-2.51,0.26-3.63,0.56c-1.33,0.36-2.4,0.36-3.33,0.05
  c-2.51-0.97-4.6-1.13-5.32-0.41c-0.56,0.56-0.87,0.51-1.74-0.41c-0.56-0.56-1.18-1.79-1.38-2.66c-0.46-1.94-4.14-4.81-6.29-4.81
  c-1.13,0-1.28-0.1-0.67-0.51c0.46-0.31,0.51-0.51,0.15-0.51c-0.36,0-1.43,0.72-2.46,1.59c-1.74,1.59-1.84,1.59-4.04,0.92
  c-1.38-0.41-2.4-1.02-2.51-1.59c-0.15-0.56-0.56-0.82-1.02-0.61c-0.97,0.36-3.89-2.25-3.07-2.76c0.41-0.26,0.46-0.67,0.15-1.13
  c-0.41-0.67-0.51-0.61-0.51,0.1c0,1.07-0.36,1.13-2.05,0.15C594.91,12.77,590.82,12.77,590,13.74z'/>
            </g>
        </svg>
    );
};