import React, { useEffect } from 'react';
import {FEATURE_LIST} from './constants';
import {Col, Row,Image, Badge} from 'antd';
// import astronautIcon from '../../../assets/img/home/astronaut.png';
import chartCircleIcon from '../../../assets/img/home/Group 8.png';
import lottie from 'lottie-web';
import loadingJson from './Astronaut_1';
import logoIcon from '../../../assets/img/home/mempad-logo.svg';
import style from './SectionThree.module.scss';

export default function SectionThree() {
    useEffect(() => {
        const id = 'Astro'
        if (!document.getElementById(id).hasChildNodes()) {

            lottie.loadAnimation({
                container: document.getElementById(id),
                animationData: loadingJson, // the dom element that will contain the animation
                renderer: 'svg',
                loop: true,
                autoplay: true,
                // the path to the animation json
            });
            lottie.destroy('Astro')
        }
    }, []);

    return (
        <section className={style.sectionThree}>
            <section className={style.memepadContent}>
                <div className={style.left}>
                    <div className={style.titleWrapper}>
                        <div className={style.btnlogo}>
                            <div><Image src={logoIcon} preview={false} className={style.badgeIcon}/></div>
                        </div>
                        <div className={style.infoContent}>
                            <p className={style.Heading}>MemePad will be hosting the MemeWars IDO on November 4th, 2021.</p>
                            <p className={style.subHeading}>Learn more on how to participate on the MemePad Website.</p>
                            <div className={style.btnCenter}>
                                <button className={style.primaryBtn} onClick={() => window.open('https://memepad.co', '_blank').focus()}>Join IDO</button>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={style.right}>
                    <div className={style.videoContainer}>
                        <div id="Astro" className={style.AstroImage} />
                    </div>
                </div>
            </section>

            <div style={{textAlign:'center'}}>
                <h2 className={style.heading}>Tokenomics</h2>
                <p className={style.titleHeading}>Below is the tokenomics of the MemeWars game economy.</p>
            </div>
            <Row className={style.chartContent}>
                <Col xs={24} sm={24} md={7} lg={7} className={style.imageContent}>
                    <Image src={chartCircleIcon} preview={false}/>
                </Col>
                <Col xs={14} sm={14} md={7} lg={7} className={style.textContentLeft}>
                    <div className={style.statsTitle}>
                        <p className={style.MainHeading}>Total Initial Supply: 250M</p>
                        <p>Memepad Stacking Program: 20%</p>
                        <p>Initial Liquidity: 20%</p>
                        <p>MemePad IDO: 20%</p>
                        <p>Marketing: 20%</p>
                        <p>Team: 20%</p>
                    </div>
                </Col>
                <Col xs={10} sm={10} md={7} lg={7} className={style.textContentRight}>
                    <div className={style.statsContent}>
                        <p style={{display:'flex', alignItems:'center'}}> <Badge color="#FF3D3F" className={style.customBadge}/> 50,000,000</p>
                        <p style={{display:'flex', alignItems:'center'}}> <Badge color="#0098FF" className={style.customBadge} /> 50,000,000</p>
                        <p style={{display:'flex', alignItems:'center'}}> <Badge color="#98E0FF" className={style.customBadge} /> 50,000,000</p>
                        <p style={{display:'flex', alignItems:'center'}}> <Badge color="#54C1FF" className={style.customBadge} /> 50,000,000</p>
                        <p style={{display:'flex', alignItems:'center'}}> <Badge color="#FF973A" className={style.customBadge} /> 50,000,000</p>
                    </div>
                </Col>
                {/* <Col span={12}>
                    <p>Memepad Stacking Program: 25%<span style={{marginLeft:'20px'}}>250,000,000</span></p>
                    <p>Marketing: 25%<span style={{marginLeft:'20px'}}>250,000,000</span></p>
                    <p>P2E Ecosystem 20%<span style={{marginLeft:'20px'}}>200,000,000</span></p>
                    <p>Team 20%<span style={{marginLeft:'20px'}}>200,000,000</span></p>
                    <p>Initial Liquidity: 5%<span style={{marginLeft:'20px'}}>50,000,000</span></p>
                    <p>MemePad Pre-Sale: 5%<span style={{marginLeft:'20px'}}>50,000,000</span></p>
                </Col> */}
            </Row>

            <div className={style.warsContent}>
                <h2 className={style.heading}>Wars Fueled by MWAR Token</h2>
                <ul className={style.list}>
                    {FEATURE_LIST.map((item, i) => (
                        <li className={style.item} key={i}>
                            <img className={style.itemImage} src={item.image} alt=""/>
                            <h3 className={style.itemHeading}>{item.heading}</h3>
                            <p className={style.itemText}>{item.text}</p>
                        </li>
                    ))}
                </ul>
            </div>
        </section>
    )
}
