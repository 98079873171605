import React from 'react';
import cn from 'classnames';

import tokenIcon from './assets/token.svg';
import atackIcon from './assets/attack.svg';
import artboardIcon from './assets/artboard.svg';
import firstVideo from './assets/Landingpage Staking Coins.mp4';
import secondVideo from './assets/Landingpage Voting.mp4';
import thirdVideo from './assets/Landingpage Victory.mp4';

import style from './SectionTwo.module.scss';

export default function SectionTwo() {
    return (
        <section className={style.sectionTwo}>
            <div className={cn(style.item, style.itemOne)}>
                <div className={style.left}>
                    <section className={style.videoContainer}>
                        <video playsInline muted loop autoPlay className={style.video} controls={false}>
                            <source src={firstVideo} type="video/mp4"/>
                        </video>   
                    </section>
                </div>

                <div className={style.right}>
                    <div className={style.titleWrapper}>
                        <strong className={style.title}>Choose & Stake in your team</strong>
                        <img src={tokenIcon} alt="" className={style.tokenIcon}/>
                    </div>
                    <p className={style.info}>Stake MWAR tokens into a team and sub-pool to gain ownership and voting
                        rights in that pool.You can stake in one of three teams, and one of three sub-pools in each team
                        (attack, defense & production).
                    </p>
                </div>
            </div>

            <div className={cn(style.item, style.itemTwo)}>
                <div className={style.left}>
                    <div className={style.titleWrapper}>
                        <strong className={style.title}>Vote & Wage War</strong>
                        <img src={atackIcon} alt="" className={style.atackIcon}/>
                    </div>
                    <p className={style.info}>If your team is chosen to declare war by the random on-chain lottery, you
                        and your team members can vote on which of the other two nations to attack.</p>
                </div>

                <div className={style.right}>
                    <div className={style.videoContainer}>
                        <video playsInline muted loop autoPlay className={style.video} controls={false}>
                            <source src={secondVideo} type="video/mp4"/>
                        </video> 
                    </div>
                </div>
            </div>

            <div className={cn(style.item, style.itemOne)}>
                <div className={style.left}>
                    <div className={style.videoContainer}>
                        <video playsInline muted loop autoPlay className={style.video} controls={false}>
                            <source src={thirdVideo} type="video/mp4"/>
                        </video>    
                    </div>
                </div>

                <div className={style.right}>
                    <div className={style.titleWrapper}>
                        <strong className={style.title}>Loot Enemy Staking Rewards</strong>
                        <img src={artboardIcon} alt="" className={style.artboardIcon}/>
                    </div>
                    <p className={style.info}>After waging war, your team will take loot from the enemy team and
                        distribute it amongst your stakers proportional to their ownership in your team’s pool.</p>
                </div>
            </div>
        </section>
    )
}
