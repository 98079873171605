import React, { useEffect, useState } from 'react';
import moment from 'moment';

import coinImg from '../../../../Maps/img/coin.png';

import style from './RoundHistoryItem.module.scss';
import { getDurations, getTimeCycles, prepareDataForRender } from '../utils';

function RoundHistoryItem(props) {
    const {
        item = {},
        roundIndex,
    } = props;

    const [time, setTime] = useState({});

    useEffect(() => {
        const time = getTimeCycles({
            stageIndex: Number(item?.stageIndex),
            stageStartTime: Number(item?.stageStart),
            victimTeam: Number(item?.victimTeam),
            durations: getDurations(Number(item?.['0']))
        });
        setTime(time)
    }, [item, roundIndex]);

    const heading = (nameStage, beginDay, endDay, isLive) => (
        <section className={style.right}>
            <div className={style.top}>
                <h2 className={style.title}>ROUND {roundIndex} - {nameStage}</h2>

                {Number(item?.stageIndex || 0) === isLive && (
                    <div className={style.isLive}>
                        <div className={style.circle} />
                        <p>LIVE NOW</p>
                    </div>
                )}
            </div>

            <p className={style.bottom}>Begins {moment.utc(beginDay).format('MM/DD/YY HH:mm UTC')}, Ends {moment.utc(endDay).format('MM/DD/YY HH:mm UTC')}</p>
        </section>
    );

    const renderStakingDay = (key, startTime, endTime) => {
        return (
            <div key={key} className={style.item}>
                <div className={style.images}>
                    <img src={coinImg} alt="" />
                </div>

                {heading('STAKING DAY', startTime, endTime, 0)}
            </div>
        )
    };

    const renderProductionDay = (key, startTime, endTime) => {
        return (
            <div key={key} style={{ display: Number(item?.stageIndex) > 0 ? 'flex' : 'none' }} className={style.item}>
                <div className={style.images}>
                    <img src={coinImg} alt="" />
                </div>

                {heading('PRODUCTION DAY', startTime, endTime, 1)}
            </div>
        )
    }

    const renderRandomDay = (key, startTime, endTime) => {
        return (
            <div key={key} style={{ display: Number(item?.stageIndex) > 1 ? 'flex' : 'none' }} className={style.item}>
                <div className={style.images}>
                    <img src={coinImg} alt="" />
                </div>

                {heading('RANDOM DAY', startTime, endTime, 2)}
            </div>
        )
    }

    const renderVotingDay = (key, startTime, endTime) => {
        return (
            <div key={key} style={{ display: Number(item?.stageIndex) > 2 ? 'flex' : 'none' }} className={style.item}>
                <div className={style.images}>
                    <img src={coinImg} alt="" />
                </div>

                {heading('VOTING DAY', startTime, endTime, 3)}
            </div>
        )
    };

    const renderBattleDay = (key, startTime, endTime) => {
        return (
            <div key={key} style={{ display: Number(item?.stageIndex) > 3 ? 'flex' : 'none' }} className={style.item}>
                <div className={style.images}>
                    <img src={coinImg} alt="" />
                </div>

                {heading('BATTLE DAY', startTime, endTime, 4)}
            </div>
        )
    };

    const renderResultDay = (key, startTime, endTime) => {
        return (
            <div key={key} style={{ display: Number(item?.stageIndex) > 4 ? 'flex' : 'none' }} className={style.item}>
                <div className={style.images}>
                    <img src={coinImg} alt="" />
                </div>

                {heading('RESULT DAY', startTime, endTime, 5)}
            </div>
        )
    };

    const dataRender = prepareDataForRender(time, {
        staking: renderStakingDay,
        production: renderProductionDay,
        random: renderRandomDay,
        voting: renderVotingDay,
        war: renderBattleDay,
        result: renderResultDay,
    });

    return (
        <div className={style.container}>
            {dataRender.map((item, i) => item.render(i, item.starTime, item.endTime))}
        </div>
    );
}

export default RoundHistoryItem;