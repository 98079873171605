import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';

import { DEFAULT_POINT_DATA, INTERVAL_TIME } from './constants';
import { getPercent, contractAddresses, formatNumberFull } from '../../utils';
import { STAGE_INFO } from '../../utils/constants';
import { updateRewardAmount, getProductionData, getDataTeamInfo } from '../../store/modules/Team/actions';

import style from './PointContainer.module.scss';
import PresenterABI from '../../abi/presenterAbi';
import PointItem from './PointItem';
import pepeImage from './img/pepe.png';
import dogeImage from './img/doge.png';
import wojakImage from './img/wojak.svg';
import { logger } from 'ethers';

export default function PointContainer() {
    const isConnect = useSelector(state => state.user?.connectWallet?.isConnect);
    const dispatch = useDispatch();

    const rewardAmount = useSelector(state => state.team.rewardAmount);

    const [pointData, setPointData] = useState(null);
    const [loop, setLoop] = useState();

    const chainId = useSelector(state => state.user.chainId);
    const addresses = contractAddresses(chainId);
    const account = useSelector(state => state.user.userAccount.accounts)[0];
    const cycleIndex = useSelector(state => state.round.cycleIndex);
    const cycleInfo = useSelector(state => state.round.cycleInfo);
    const stageIndex = cycleInfo ? cycleInfo.stageIndex : 0;

    const stageStartDate = cycleInfo ? cycleInfo.stageStart * 1000 : null;
    const stageDuration = cycleInfo ? parseInt(cycleInfo.stageDuration) : 0;
    const stageStartObject = dayjs(stageStartDate);
    const stageEndObjet = stageStartObject.add(stageDuration, 'second');

    useEffect(async () => {
        try {
            if (window.web3 && chainId && window.web3.eth) {
                const presenterContract = new window.web3.eth.Contract(PresenterABI, addresses['PRESENTER']);
                if (stageIndex === STAGE_INFO.PRODUCTION.INDEX || stageIndex === STAGE_INFO.PRODUCTION.INDEX.toString()) {
                    // get data reward by second
                    handleInterval(presenterContract);
                } else {
                    // get normal data team
                    clearInterval(loop);
                    const dataTeamInfo = await getDataTeamInfo(presenterContract, account);
                    dispatch(updateRewardAmount(dataTeamInfo));
                }
            }
        } catch (e) {
            logger.debug(e);
        }
    }, [chainId, account, cycleIndex, stageIndex]);

    useEffect(() => {
        if (rewardAmount && isConnect) {
            const newPointData = {
                doge: {
                    value: `${getPercent(rewardAmount?.doge, rewardAmount?.total)}`,
                    title: `${formatNumberFull(rewardAmount.doge, 4)} MWAR`,
                    startColor: '#ffb63f',
                    endColor: '#ffb63f',
                    color: '#ffffff'
                },
                wojak: {
                    value: `${getPercent(rewardAmount?.wojak, rewardAmount?.total)}`,
                    title: `${formatNumberFull(rewardAmount.wojak, 4)} MWAR`,
                    startColor: '#e66969',
                    endColor: '#e64646',
                    color: '#ffffff'
                },
                pepe: {
                    value: `${getPercent(rewardAmount?.pepe, rewardAmount?.total)}`,
                    title: `${formatNumberFull(rewardAmount.pepe, 4)} MWAR`,
                    startColor: '#62ad69',
                    endColor: '#23a62f',
                    color: '#ffffff'
                }
            };

            setPointData(newPointData);
            return;
        }

        setPointData(DEFAULT_POINT_DATA);
    }, [rewardAmount, isConnect]);

    const handleInterval = (presenterContract) => {
        setLoop(setInterval(async () => {
            try {

                if (stageStartDate) {
                    // check remain time => clear interval 
                    const now = dayjs();
                    let diffSeconds = stageEndObjet.diff(now, 'second');
                    if (diffSeconds < INTERVAL_TIME) {
                        clearInterval(loop);
                        return;
                    }
                }

                // CALL REWARD PRODUCTION BY SECOND
                const dataTeamInfo = await getProductionData(presenterContract, account);
                dispatch(updateRewardAmount(dataTeamInfo));
            } catch {
                clearInterval(loop);
            }
        }, INTERVAL_TIME * 1000))
    }

    return (
        <>
            {pointData &&
                <div className={style.pointSection}>
                    <PointItem {...pointData.doge} avatar={dogeImage} />
                    <PointItem {...pointData.wojak} avatar={wojakImage} />
                    <PointItem {...pointData.pepe} avatar={pepeImage} />
                </div>
            }
        </>
    )
}