import React, { useRef, useCallback } from 'react';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

import RoundItem from './RoundHistoryItem';
import RoundLast from './RoundHistoryLastItem';
import Modal from '../../../Modal';
// import Button from '../../../Button';

import closeIcon from '../../../ConnectToWalletModal/img/close.png';

import style from './RoundHistoryModal.module.scss';

const antIcon = <LoadingOutlined style={{ fontSize: 24, color: '#61ac67' }} spin />;

export default function RoundHistoryModal(props) {
    const {
        visible,
        onCancel,
        items = [],
        cycleInfo = {},
        roundIndex = [],
        onLoadMore,
        isLoading,
        isHasMore,
        cycleIndex,
        // listDuration
    } = props;

    const observer = useRef();
    const lastElement = useCallback(node => {
        if (isLoading) return;

        if (observer.current) observer.current.disconnect();

        observer.current = new IntersectionObserver(entries => {
            entries.forEach((entry) => {
                if (!isLoading && entry.intersectionRatio > 0) {
                    onLoadMore && onLoadMore()
                }
            });
        }, {
            root: null,
            rootMargin: '0px',
            threshold: 0.5
        });

        if (node) observer.current.observe(node);
    }, [isLoading, isHasMore]);

    const lastList = items && items;

    return (
        <Modal
            wrapperClass={style.container}
            simple
            width={'30%'}
            handleCancel={onCancel}
            visible={visible}
            closable={false}
        >
            <div className={style.header}>
                <h2 className={style.titleModal}>Round History</h2>

                <div onClick={() => onCancel()} className={style.closeIcon}>
                    <img src={closeIcon} alt="" />
                </div>
            </div>

            <p className={style.detail}>View all past rounds and timestamps</p>

            <div className={style.list}>
                <RoundItem
                    item={cycleInfo}
                    roundIndex={cycleIndex}
                    lastList={lastList[0]}
                />

                {lastList && lastList.length ? lastList.map((e, i) => (
                    <div ref={lastList.length === i + 1 ? lastElement : null} key={i}>
                        <RoundLast
                            item={e}
                            roundIndex={roundIndex[i]}
                        />
                    </div>
                )) : (
                    null
                )}

                <div className={style.loadMore}>
                    {isLoading && <Spin indicator={antIcon} />}
                </div>
            </div>
        </Modal>
    )
}