import React from 'react';
import Router from './router';

import {UseAppInit} from './store/modules/App/hooks';
import {UseUserInit} from './store/modules/User/hooks';
import Logger from 'js-logger';
import {LoaderContainer} from './components/PageLayout/Loader';
import {WrongNetworkModalContainer} from './components/WrongNetworkModal';
import {TermsModalContainer} from './components/TermsModal';
import { UseCycleHooks } from './store/modules/Round/hooks';
import { UseTeamHooks } from './store/modules/Team/hooks';
import { LandscapeModalContainer } from './components/LandscapeModal';


function App() {
    Logger.useDefaults();
    // Initiate state
    UseAppInit();
    UseUserInit();
    UseCycleHooks();
    UseTeamHooks();

    return (
        <>
            <Router/>
            <LoaderContainer/>
            <WrongNetworkModalContainer/>
            <TermsModalContainer/>
            <LandscapeModalContainer />
        </>
    );
}

export default App;
