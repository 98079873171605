import React from 'react';
import {Col, Row} from 'antd';
import pancakeIcon from '../../../assets/img/home/pancake.png'
import telegramIcon from '../../../assets/img/social/telegram.svg';
import cn from 'classnames';

import style from './SectionFive.module.scss';

export default function SectionFive() {
    return (
        <section className={style.sectionFive}>
            <Row type="flex" align="middle" className={style.wrapper}>
                <Col xs={24} sm={24} md={24} lg={16} className={style.information}>
                    <section className={style.content}>
                        <strong className={style.heading}>Are you Ready for Battle?</strong>

                        <div className={style.buttonContainer}>
                            <a className={cn(style.btnJoin, style.btn)} href="https://t.me/officialmemewars" target="_blank" rel="noreferrer">
                                <div className={style.btnWrapper}>
                                    <img src={telegramIcon} alt="telegram"
                                        className={style.telegramIcon}/>
                                    <span className={style.label}>Join Chat</span>
                                </div>
                            </a>

                            <a className={cn(style.btnContact, style.btn)} href="https://pancakeswap.finance/swap#/swap?outputCurrency=0x9f28455a82baa6b4923a5e2d7624aaf574182585" target="_blank" rel="noreferrer">
                                <div className={style.btnWrapper}>
                                    <img src={pancakeIcon} alt="pancake"
                                        className={style.pancakeIcon}/>
                                    <span className={style.label}>Buy MWAR</span>
                                </div>
                            </a>
                        </div>
                    </section>
                </Col>
            </Row>
        </section>
    )
}
