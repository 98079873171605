import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import cn from 'classnames';
import { Dropdown, Menu } from 'antd';
import Jazzicon, { jsNumberForAddress } from 'react-jazzicon';

import useRoundHistory from './useRoundHistory';
import EmailNotificationModal from './EmailNotificationPopup';
import RoundHistoryModal from './RoundHistoryModal';

import Button from '../../Button';
import { ProfileModalContainer } from '../../ProfileModal';

import {PAGE_HOME_PATH} from '../../../router/constants';
import {
    disconnect,
    // fetchWalletInfo
} from '../../../store/modules/User/actions';
import { setConnectModalVisible } from '../../../store/modules/App/actions';
import {
    setVisibleRoundHistory,
} from '../../../store/modules/Round/actions';
import formatNumber, { formatBlockchainAddress } from '../../../utils';

// import flagUKIcon from './img/flag_uk.svg';
import moreIcon from './img/more.png';
import balanceIcon from './img/balanceIcon.svg';
// import emailIcon from './img/email.png';
// import disconnectIcon from './img/logout.png';
// import historyIcon from './img/history.png';
import logo from '../../../assets/img/logo.png';

import style from './Header.module.scss';

export default function Header(props) {
    const dispatch = useDispatch();

    const {
        wrapperClass,
        fullWidth
    } = props;

    const [isVisibleMore, setVisibleMore] = useState(false);
    const [isVisibleProfileModal, setVisibleProfileModal] = useState(false);
    const [isVisibleEmailNotificationModal, setVisibleEmailNotificationModal] = useState(false);
    const [myBalance, setMyBalance] = useState(0);

    const [load, setLoad] = useState(false);

    const {isLandscapeVisible} = useSelector(state => state.app);
    const isConnected = useSelector(state => state.user.connectWallet.isConnect);
    const address = useSelector(state => state.user.userAccount.accounts ? state.user.userAccount.accounts[0] : '');
    const balance = useSelector(state => state.user.userAccount.tokenBalance);

    const isVisibleRoundHistory = useSelector(state => state.round?.isVisibleRoundHistory);
    const cycleInfo = useSelector(state => state.round?.cycleInfo);
    const cycleIndex = useSelector(state => state.round?.cycleIndex);
    const roundHistoryList = useSelector(state => state.round?.roundHistoryList?.response);
    const roundIndexList = useSelector(state => state.round?.roundHistoryList?.roundIndex);

    // const listDuration = useSelector(state => state.round?.listDuration);

    const { isLoading } = useRoundHistory({ load, setLoad });

    useEffect(() => {
        if (balance) {
            setMyBalance(balance);
        }
    }, [balance, address]);

    const handleProfileModalVisible = () => {
        setVisibleProfileModal(true);
    };

    const handleProfileModalHidden = () => {
        setVisibleProfileModal(false);
    };

    const handleShowEmailNotificationModal = (value) => {
        // dispatch(fetchWalletInfo(address)).then(res => console.log(res))

        setVisibleEmailNotificationModal(value);
    };

    const handleConnectWallet = () => {
        dispatch(setConnectModalVisible(true));
    };

    const disconnectWallet = () => {
        dispatch(disconnect());
    };

    const handleShowRoundHistory = (value) => {
        dispatch(setVisibleRoundHistory(value));
    };

    const handleLoadMore = () => {
        setLoad(true);
    }

    const menu = (
        <Menu
            style={{ borderRadius: 5, overflow: 'hidden' }}
        >
            <Menu.Item
                key={'1'}
                style={{ padding: '7rem 20rem' }}
                onClick={() => handleShowEmailNotificationModal(true)}
                className={style.dropdownItem}
            >
                {/* <img src={emailIcon} alt="" /> */}
                <span className={style.itemMenu}> Email Notification</span>
            </Menu.Item>

            <Menu.Item
                key={'2'}
                style={{ padding: '7rem 20rem' }}
                onClick={() => handleShowRoundHistory(true)}
                className={style.dropdownItem}
            >
                {/* <img src={historyIcon} alt="" /> */}
                <span  className={style.itemMenu}> Round History</span>
            </Menu.Item>

            <Menu.Item
                key={'3'}
                style={{ padding: '7rem 20rem' }}
                onClick={disconnectWallet}
                className={style.dropdownItem}
            >
                {/* <img src={disconnectIcon} alt="" /> */}
                <span  className={style.itemMenu}> Disconnect</span>
            </Menu.Item>
        </Menu>
    );

    return (
        <header className={cn(style.container, wrapperClass)}>
            <div className={cn(style.content, {[style.fullWidth]: fullWidth})}>
                <div className={style.left}>
                    <Link className={style.logoLink} to={PAGE_HOME_PATH}>
                        <img src={logo} alt="logo" className={style.logo} />
                    </Link>
                </div>
                <div className={style.right}>
                    {/* style={{ visibility: 'hidden' }} */}
                    <div>
                        {!isConnected ? (
                            <Button wrapperClass={style.connectBtn} onClick={handleConnectWallet}>Connect Wallet</Button>
                        ) : (
                            <div className={style.wallet} onClick={handleProfileModalVisible}>
                                <span className={style.walletBalance}>
                                    {formatNumber(myBalance)}
                                    <img src={balanceIcon} className={style.walletIcon} alt='walleticon' />
                                </span>
                                <span className={style.walletAddress}>
                                    {formatBlockchainAddress(address)}
                                    <div className={style.addressIcon}>
                                        <Jazzicon paperStyles={{ maxWidth: '100%', maxHeight: '100%' }} diameter={18} seed={jsNumberForAddress(address || '')} />
                                    </div>
                                    {/* <img src={addressIcon} className={style.walletIcon} alt='walleticon' /> */}
                                </span>
                            </div>
                        )}
                    </div>

                    <div>
                        {
                            isConnected && (
                                <span  id='more-icon'>
                                    <Dropdown
                                        getPopupContainer={() => document.getElementById('more-icon')}
                                        placement={'bottomLeft'}
                                        visible={isVisibleMore}
                                        onVisibleChange={(isVisible) => { setVisibleMore(isVisible); }}
                                        // visible={isLeaveDropdown}
                                        // onVisibleChange={() => setLeaveDropdown(!isLeaveDropdown)}
                                        overlay={menu}
                                    >
                                        <Button
                                            icon={moreIcon}
                                            iconWidth={'100%'}
                                            iconClass={style.moreIcon}
                                            wrapperClass={cn(style.headerBtn, style.languagelist)}
                                            onClick={() => setVisibleMore(true)}
                                        />
                                    </Dropdown>
                                </span>
                            )
                        }
                    </div>
                </div>
            </div>

            <ProfileModalContainer visible={isVisibleProfileModal} onCancel={handleProfileModalHidden} closable={false} />
            <EmailNotificationModal visible={isVisibleEmailNotificationModal} onCancel={() => handleShowEmailNotificationModal(false)} />
            <div className={style.roundHistoryModal}>
                <RoundHistoryModal
                    visible={isVisibleRoundHistory && !isLandscapeVisible}
                    onCancel={() => handleShowRoundHistory(false)}
                    items={roundHistoryList}
                    roundIndex={roundIndexList}
                    cycleInfo={cycleInfo}
                    onLoadMore={handleLoadMore}
                    // isHasMore={isHasMore}
                    isLoading={isLoading}
                    cycleIndex={Number(cycleIndex)}
                    // listDuration={listDuration}
                />
            </div>
        </header>
    );
}
