export const getDurations = stageDuration => {
    const staking = Number(stageDuration);
    const production = Number(stageDuration);
    const random = Number(stageDuration) * 10 / 100;
    const voting = Number(stageDuration) * 40 / 100;
    const war = Number(stageDuration) * 20 / 100;
    const result = Number(stageDuration) * 30 / 100;

    return [
        staking,
        production,
        random,
        voting,
        war,
        result,
    ];
};

export const getTimeCycles = ({ stageIndex, stageStartTime, victimTeam, durations }) => {
    const staking = durations[0];
    const production = durations[1];
    const random = durations[2];
    const voting = durations[3];
    const war = durations[4];
    const result = durations[5];

    let timeDurationToCalc = 0;

    for (let i = stageIndex - 1; i >= 0; i--) {
        timeDurationToCalc += durations[i];
    }

    if (stageIndex === 5 && !(victimTeam < 3)) {
        timeDurationToCalc -= durations[4];
    }

    const hasWar = victimTeam < 3;

    // Calc startTime of stageIndex = 0
    const stakingStartTime = stageStartTime - timeDurationToCalc;

    // Calc startTime of stageIndex = 1
    const productionStartTime = stakingStartTime + staking;

    // Calc startTime of stageIndex = 2
    const randomStartTime = productionStartTime + production;

    // Calc startTime of stageIndex = 3
    const votingStartTime = randomStartTime + random;

    // Calc startTime of stageIndex = 4
    const warStartTime = hasWar ? votingStartTime + voting : 0;

    // Calc startTime of stageIndex = 5
    const resultStartTime = hasWar ? warStartTime + war : votingStartTime + voting;

    return {
        staking: {
            startTime: stakingStartTime,
            duration: staking,
        },
        production: {
            startTime: productionStartTime,
            duration: production,
        },
        random: {
            startTime: randomStartTime,
            duration: random,
        },
        voting: {
            startTime: votingStartTime,
            duration: voting,
        },
        war: {
            startTime: warStartTime,
            duration: hasWar ? war : 0,
        },
        result: {
            startTime: resultStartTime,
            duration: result,
        },
    };
};

export const prepareDataForRender = (time, render) => [
    {
        duration: time?.staking?.duration,
        starTime: time?.staking?.startTime * 1000,
        endTime: (time?.staking?.startTime + time?.staking?.duration) * 1000,
        render: render.staking
    },
    {
        duration: time?.production?.duration,
        starTime: time?.production?.startTime * 1000,
        endTime: (time?.production?.startTime + time?.production?.duration) * 1000,
        render: render.production
    },
    {
        duration: time?.random?.duration,
        starTime: time?.random?.startTime * 1000,
        endTime: (time?.random?.startTime + time?.random?.duration) * 1000,
        render: render.random
    },
    {
        duration: time?.voting?.duration,
        starTime: time?.voting?.startTime * 1000,
        endTime: (time?.voting?.startTime + time?.voting?.duration) * 1000,
        render: render.voting
    },
    {
        duration: time?.war?.duration,
        starTime: time?.war?.startTime * 1000,
        endTime: (time?.war?.startTime + time?.war?.duration) * 1000,
        render: render.war
    },
    {
        duration: time?.result?.duration,
        starTime: time?.result?.startTime * 1000,
        endTime: (time?.result?.startTime + time?.result?.duration) * 1000,
        render: render.result
    },
].filter(item => item?.duration !== 0).reverse()