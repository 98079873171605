import { handleActions } from 'redux-actions';
import {persistReducer} from 'redux-persist';
import localForage from 'localforage';

const initialState = {
    isMobile: false,
    isTablet: false,
    isDesktop: false,
    connectModalVisible: false,
    load: {
        requesting: false,
        loaded: false
    },
    isAutoplay: false,
    isDarkMode: false,
    isLandscapeVisible: false
};

export const SET_IS_LANDSCAPE_VISIBLE = 'SET_IS_LANDSCAPE_VISIBLE';
export const SET_IS_MOBILE = 'SET_IS_MOBILE';
export const SET_IS_TABLET = 'SET_IS_TABLET';
export const SET_IS_DESKTOP = 'SET_IS_DESKTOP';
export const SET_CONNECT_MODAL_VISIBLE = 'SET_CONNECT_MODAL_VISIBLE';
export const LOADING_REQUEST = 'LOADING_REQUEST';
export const LOADING_SUCCESS = 'LOADING_SUCCESS';
export const SET_AUTOPLAY = 'SET_AUTOPLAY';
export const SET_DARK_MODE = 'SET_DARK_MODE';

let appReducer = handleActions({
    [SET_DARK_MODE]: (state, {payload}) => ({
        ...state,
        isDarkMode: payload,
    }),
    [SET_AUTOPLAY]: (state, {payload}) => ({
        ...state,
        isAutoplay: payload,
    }),
    [SET_CONNECT_MODAL_VISIBLE]: (state, {payload}) => ({
        ...state,
        connectModalVisible: payload,
    }),
    [SET_IS_MOBILE]: (state) => ({
        ...state,
        isMobile: true,
        isTablet: false,
        isDesktop: false
    }),
    [SET_IS_TABLET]: (state) => ({
        ...state,
        isMobile: false,
        isTablet: true,
        isDesktop: false
    }),
    [SET_IS_DESKTOP]: (state) => ({
        ...state,
        isMobile: false,
        isTablet: false,
        isDesktop: true
    }),
    [LOADING_REQUEST]: (state) => ({
        ...state,
        load: {
            ...state.load,
            requesting: true
        }
    }),
    [LOADING_SUCCESS]: (state) => ({
        ...state,
        load: {
            ...state.load,
            requesting: false,
            loaded: true
        }
    }),
    [SET_IS_LANDSCAPE_VISIBLE]: (state, { payload }) => ({
        ...state,
        isLandscapeVisible: payload
    })
}, initialState);

appReducer = persistReducer({
    key: 'app',
    storage: localForage,
    whitelist: ['isDarkMode', 'isAutoplay']
}, appReducer);

export default appReducer;
