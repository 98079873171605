import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Tooltip } from 'antd';
// import Button from '../Button';
import StakeModal  from '../StakeModal';

import { setConnectModalVisible } from '../../store/modules/App/actions';

// import { ReactComponent as StakeIcon } from './img/stake.svg';

import stakeIcon from './img/stake.svg';

import style from './Stake.module.scss';

export default function StakeContainer() {
    const dispatch = useDispatch();

    const [isVisibleStakeModal, setVisibleStakeModal] = useState(false);

    const {isMobile, isTablet} = useSelector(state => state.app);
    const isConnected = useSelector(state => state.user.connectWallet.isConnect);
    const cycleInfo = useSelector(state => state.round.cycleInfo);

    const stageIndex = cycleInfo ? parseInt(cycleInfo.stageIndex) : 0;

    const handleModalVisible = () => {
        if (isConnected) {
            setVisibleStakeModal(true);
        } else {
            dispatch(setConnectModalVisible(true));
        }
    };

    const handleModalHidden = () => {
        setVisibleStakeModal(false);
    };

    return (
        <div>
            {/* <Button
                wrapperClass={style.stakeBtn}
                text={'STAKE'}
                icon={<StakeIcon />}
                iconClass={style.icon}
                iconWidth={50}
                iconAlignStart
                disabled={stageIndex !== 0}
                onClick={handleModalVisible}
                hasTooltip={stageIndex !== 0}
                toolTipText={'It not staking day'}
                toolTipPlacement={'topLeft'}
            /> */}

            {stageIndex !== 0 ? (
                <Tooltip
                    overlayClassName={style.tooltip}
                    placement={'topRight'}
                    title={'It is not staking day'}
                >
                    <button
                        className={style.stakeBtnDisable}
                        // disabled={stageIndex !== 0}
                        style={{ cursor: 'not-allowed' }}
                        onClick={() => {}}
                    >
                        <img className={style.stakeIcon} src={stakeIcon} alt="" />
                        STAKE
                    </button>
                </Tooltip>
            ) : (
                <button
                    className={style.stakeBtn}
                    onClick={() => handleModalVisible()}
                >
                    <img className={style.stakeIcon} src={stakeIcon} alt="" />
                    STAKE
                </button>
            )}

            <StakeModal
                stageIndex={stageIndex}
                visible={isVisibleStakeModal}
                handleClose={handleModalHidden}
                handleCancel={handleModalHidden}
                isMobile={isMobile}
                isTablet={isTablet}
            />
        </div>
    );
}
