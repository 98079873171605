import React from 'react';
import { useDispatch } from 'react-redux';

import Modal from '../Modal';
import { closeNetworkAction } from '../../store/modules/User/actions';

import loading from '../../assets/img/loading.png';
import style from './WrongNetworkModal.module.scss';

export default function WrongNetworkModal(props) {
    const { wrapperClass, isMobile, isTablet, ...restProps } = props;

    const dispatch = useDispatch();

    const closeNetwork = () => {
        dispatch(closeNetworkAction());
    }

    return (
        <Modal
            wrapperClass={wrapperClass}
            title={'Connect to a wallet'}
            width={450}
            closeable={(isMobile || isTablet)}
            maskClosable={false}
            handleCancel={closeNetwork}
            {...restProps}
        >
            <img src={loading} alt="" className={style.loading} />
            <h3 className={style.title}>
                Wrong network
            </h3>
            <p className={style.text}>
                Change network to Binance Smart Chain
            </p>
        </Modal>
    );
}
