import React from 'react';
import cn from 'classnames';
import style from './ProgressBar.module.scss';

export const PrgressBar = (props) => {
    const {
        value,
        title,
        direction,
        classes,
        startColor,
        endColor,
        loading,
        // highlight
    } = props;

    return (
        <div
            className={cn(style.container, classes)}
            style={{ justifyContent: direction === 'left' ? 'flex-start' : 'flex-end', alignItems: 'center' }}
        >
            {direction === 'left' && (
                <div
                    style={{
                        width: loading ? '100%' : `calc(${value}%)`,
                        height: '100%',
                        backgroundImage: !loading && `linear-gradient(to right, ${startColor}, ${endColor})`,
                        display: value === 0 && 'none'
                    }}
                    className={cn(style.itemLeft, {
                        [style.loading]: loading
                    })}
                />
            )}

            {direction === 'left' && (
                <p
                    style={{
                        position: 'absolute',
                        left: '4rem'
                    }}
                    className={style.textLeft}
                >
                    {loading ? 'Loading...' : title}
                </p>
            )}
            {direction === 'right' && (
                <div
                    style={{
                        width: `calc(${value}% + 4rem`,
                        height: '100%',
                        backgroundImage: `linear-gradient(to left, ${startColor}, ${endColor})`,
                        display: value === 0 && 'none',
                    }}
                    className={style.itemRight}
                />
            )}

            {direction === 'right' && (
                <p
                    style={{
                        position: 'absolute',
                        // right: '12rem',
                        marginBottom: 0
                    }}
                    className={style.textShadow}
                >
                    {title}
                </p>
            )}
        </div>
    )
};
