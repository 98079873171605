import React, { useEffect } from 'react';
import Layout from '../../components/Layout';
import {Maps} from '../../components/Maps';
import { useDispatch } from 'react-redux';
import { isLandscapeOrientation } from '../../utils';
import { setIsLandscapeVisible } from '../../store/modules/App/actions';

export default function GamePlay() {
    const dispatch = useDispatch();
    useEffect(() => {
        if (!isLandscapeOrientation()) {
            dispatch(setIsLandscapeVisible(true));
        } else {
            dispatch(setIsLandscapeVisible(false));
        }
    }, [dispatch])

    return (
        <Layout>
            <Maps />
        </Layout>
    )
}
