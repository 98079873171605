import React from 'react';
import {Col, Row} from 'antd';
import macIcon from './assets/mac.svg';
import androidIcon from './assets/android.svg';
import webIcon from './assets/web.png';
import windowsIcon from './assets/windows.svg';
import huaweiIcon from './assets/huawei.png';
import iphoneImage from './assets/iphone.png';
import style from './SectionFour.module.scss';

export default function SectionFour() {
    return (
        <section className={style.sectionFour}>
            <Row type="flex" align="middle" className={style.sectionFourContainer}>
                <Col xs={12} sm={12} md={12} lg={12} className={style.left}>
                    <img src={iphoneImage} alt="iphone" className={style.image}/>
                </Col>
                <Col xs={12} sm={12} md={12} lg={12} className={style.right}>
                    <h2 className={style.title}>Mobile Friendly Gameplay for all Devices.</h2>
                    <p className={style.info}>The MemeWars web app will be available on both desktop and mobile devices,
                        optimized for all screen sizes and compatible with the leading crypto wallets like TrustWallet,
                        Metamask, Coinbase Wallet and more.</p>
                    <div className={style.iconGroup}>
                        <img src={macIcon} alt="mac" className={style.icon}/>
                        <img src={androidIcon} alt="android"
                            className={style.icon}/>
                        <img src={webIcon} alt="web" className={style.icon}/>
                        <img src={windowsIcon} alt="windows"
                            className={style.icon}/>
                        <img src={huaweiIcon} alt="huawei" className={style.icon}/>
                    </div>
                </Col>
            </Row>
        </section>
    )
}
