import { getCycleInfo } from '../../../utils/callHelpers';

export const getRoundHistoriesList = async (currentCycleIndex, offset = 10, presenterABI, address) => {
    const currentIndex = parseInt(currentCycleIndex) - 1;
    const pastCurrentIndex = currentIndex - offset || 1; // limit 50

    let roundList = [];
    let roundIndex = [];
    let response = null;
    let lastItem;

    if (lastItem <= 0) return;

    try {
        const presenterContract = new window.web3.eth.Contract(presenterABI, address);

        for (let index = currentIndex; index >= pastCurrentIndex; index--) {
            if (index <= 0) break;

            roundIndex.push(index);
            roundList.push(getCycleInfo(presenterContract, index));
            lastItem = index;
        }

        response = await Promise.all(roundList)
            .then(res => {
                return res;
            })
            .catch(err => err)
        return { response, roundIndex, offset, lastItem };
    } catch (error) {
        return error
    }
}