import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { updateTeamInfo } from './actions';

import { contractAddresses } from '../../../utils';
import PresenterABI from '../../../abi/presenterAbi';
import { getTeamInfo, getPendingReward } from '../../../utils/callHelpers';

export const UseTeamHooks = () => {
    const dispatch = useDispatch();

    const [isReload, setReload] = useState(0);

    const chainId = useSelector(state => state.user.chainId);
    const account = useSelector(state => state.user.userAccount.accounts)[0];
    const isConnect = useSelector(state => state.user.connectWallet.isConnect);
    const stageIndex = useSelector(state => state.round?.cycleInfo?.stageIndex ?? 0);

    useEffect(async () => {
        try {
            if (chainId && account && window.web3) {
                const addresses = contractAddresses(chainId);
                const presenterContract = new window.web3.eth.Contract(PresenterABI, addresses['PRESENTER']);

                const dataTeamInfo = await callDataTeamData(presenterContract, account);
    
                dispatch(updateTeamInfo(dataTeamInfo));
            }
        } catch (error) {
            return error;
        }
    }, [chainId, account, isReload, stageIndex]);

    useEffect(() => {
        if (!isConnect) return;
    
        // this is the live voting day
        if (parseInt(stageIndex) === 3) {
            const timer = setInterval(() => {
                setReload(prevState => prevState + 1);
            }, 2000);
    
            return () => {
                clearInterval(timer);
            }
        }
    }, [parseInt(stageIndex)]);
};


export const callDataTeamData = async (presenterContract, account) => {
    const dogeInfo = await getTeamInfo(presenterContract, 0);
    const pepeInfo = await getTeamInfo(presenterContract, 1);
    const wojakInfo = await getTeamInfo(presenterContract, 2);

    const dogeInfoPendingReward = await getPendingReward(presenterContract, 0, account);
    const pepeInfoPendingReward = await getPendingReward(presenterContract, 1, account);
    const wojakInfoPendingReward = await getPendingReward(presenterContract, 2, account);

    return {
        pepe: { ...pepeInfo, pendingReward: pepeInfoPendingReward },
        doge: { ...dogeInfo, pendingReward: dogeInfoPendingReward },
        wojak: { ...wojakInfo, pendingReward: wojakInfoPendingReward }
    }
}