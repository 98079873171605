import React from 'react';
import Modal from '../Modal';
import Button from '../Button';
import logo from '../../assets/img/logo.png';

import style from './TermsOfServiceModal.module.scss';

export default function TermsModal(props) {
    const {onApply, ...restProps} = props;

    return (
        <Modal
            wrapperClass={style.container}
            closable={false}
            simple
            {...restProps}
        >
            <div className={style.logo_container}>
                <img src={logo} alt="logo" className={style.logo} />
            </div>
            <h2 className={style.heading}>
                Welcome to MEMEWARS
            </h2>
            <p className={style.text}>
                This product is in Beta development and there may be some unexpected bugs.
                The beta data may be deleted in production version.
            </p>
            <Button
                wrapperClass={style.button}
                text={'Accept Cookies'}
                primary
                green
                onClick={onApply}
            />
        </Modal>
    );
}
