import React from 'react';
import cn from 'classnames';

import style from './Switch.module.scss';

export default function Switch(props) {
    const { onChange, options, selectedOption } = props;
    return (
        <div className={style.toggleSwitch}>
            {options.map(option =>
                <div key={option.id} role='button' className={cn(style.option, {[style.active]: selectedOption.id === option.id})} onClick={() => onChange(option)}>
                    {option.label}
                </div>
            )}
        </div>
    );
}
