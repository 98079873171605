import dogeImage from '../assets/img/maps/doge.png';
import wojakImg from '../assets/img/maps/wojak.png';
import pepeImg from '../assets/img/maps/pepe.png';

import dogeImageSmall from '../assets/img/maps/doge_small.png';
import wojakImgSmall from '../assets/img/maps/wojak_small.png';
import pepeImgSmall from '../assets/img/maps/pepe_small.png';

export const CHAIN_MAINNET = 56;
export const CHAIN_TESTNET = 97;

export const RPC_URL = {
    56: 'https://bsc-dataseed.binance.org/',
    97: 'https://data-seed-prebsc-1-s1.binance.org:8545/'
};

export const TEAMS = {
    DOGE: {
        ID : 0,
        CODE: 'doge',
        NAME_NATION: 'Doge Nation',
        IMG : dogeImage,
        IMG_SMALL : dogeImageSmall
    },
    PEPE: {
        ID : 1,
        CODE: 'pepe',
        NAME_NATION: 'Pepe Nation',
        IMG : pepeImg,
        IMG_SMALL : pepeImgSmall
    },
    WOJAK: {
        ID : 2,
        CODE: 'wojak',
        NAME_NATION: 'Wojak Nation',
        IMG : wojakImg,
        IMG_SMALL : wojakImgSmall
    }
}


export const STAKING = 'STAKING';
export const PRODUCTION = 'PRODUCTION';
export const RANDOM_WAR = 'RANDOM_WAR';
export const VOTING = 'VOTING';
export const WAR_TIME = 'WAR_TIME';
export const RESULT = 'RESULT';

export const STAGE_INFO = {
    STAKING: {
        CODE: STAKING,
        INDEX: 0,
        STAGE_NAMES : 'STAKING'
    },
    PRODUCTION: {
        CODE: PRODUCTION,
        INDEX: 1,
        STAGE_NAMES : 'PRODUCTION'
    },
    RANDOM_WAR: {
        CODE: RANDOM_WAR,
        INDEX: 2,
        STAGE_NAMES : 'RANDOM WAR'
    },
    VOTING: {
        CODE: VOTING,
        INDEX: 3,
        STAGE_NAMES : 'VOTING'
    },
    WAR_TIME: {
        CODE: WAR_TIME,
        INDEX: 4,
        STAGE_NAMES : 'WAR'
    },
    RESULT: {
        CODE: RESULT,
        INDEX: 5,
        STAGE_NAMES : 'RESULT'
    }
}


export const TEAMS_LIST = Object.values(TEAMS);
export const STAGE_INFO_LIST = Object.values(STAGE_INFO);