import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Modal from '../Modal';
import ProviderList from './ProviderList';
import { CONNECTION_TYPES, PROVIDER_ITEMS, LOADING_STATUSES } from './constants';

import { connectMetaMask, connectToWalletConnect } from '../../store/modules/User/actions';

import closeIcon from './img/close.png';

import style from './ConnectToWallet.module.scss'; 

export default function ConnectToWalletModal(props) {
    const dispatch = useDispatch();

    const {
        wrapperClass,
        onCancel,
        visible,
        ...restProps
    } = props;

    const [selectedItem, setSelectedItem] = useState(null);
    const [loadingStatus, setLoadingStatus] = useState(null);

    const isConnected = useSelector(state => state.user?.connectWallet?.isConnect);

    const dispatchConnect = () => dispatch(connectMetaMask());
    const dispatchWalletConnect = () => dispatch(connectToWalletConnect());

    useEffect(() => {
        !selectedItem && setLoadingStatus(null);

        return () => {
            setSelectedItem(null);
        }
    }, [selectedItem, isConnected]);

    const onSelect = async (item) => {
        setSelectedItem(item);
        window.localStorage.setItem('connectorId', item.connector);
        if (item.connector === CONNECTION_TYPES.metamask) {
            setLoadingStatus(LOADING_STATUSES.LOADING);
            dispatchConnect()
                .then(res => {
                    if (res) {
                        setLoadingStatus(LOADING_STATUSES.LOADED);
                        onCancel();
                    } else {
                        setLoadingStatus(LOADING_STATUSES.ERROR);
                    }
                });
        } else if (item.connector === CONNECTION_TYPES.walletconnect) {
            setLoadingStatus(LOADING_STATUSES.LOADING);
            dispatchWalletConnect()
                .then(res => {
                    if (res) {
                        setLoadingStatus(LOADING_STATUSES.LOADED);
                        onCancel();
                    } else {
                        setLoadingStatus(LOADING_STATUSES.ERROR);
                    }
                });
        } else {
            setLoadingStatus(LOADING_STATUSES.ERROR);
        }
    };

    return (
        <Modal
            wrapperClass={wrapperClass}
            // title={renderTitle(selectedItem, setSelectedItem)}
            visible={visible}
            width={390}
            closeable={false}
            onCancel={onCancel}
            {...restProps}
        >
            <div className={style.header}>
                <h2 className={style.titleModal}>Connect Wallet</h2>

                <div onClick={() => onCancel()} className={style.closeIcon}>
                    <img src={closeIcon} alt="" />
                </div>
            </div>

            <ProviderList
                items={PROVIDER_ITEMS}
                selectedItem={selectedItem}
                onSelect={onSelect}
                onRepeat={onSelect}
                requesting={loadingStatus === LOADING_STATUSES.LOADING}
                error={loadingStatus === LOADING_STATUSES.ERROR}
            />
        </Modal>
    );
}

// function renderTitle(selectedItem, setSelectedItem) {
//     if (selectedItem) {
//         return (
//             <Button
//                 wrapperClass={style.back}
//                 onClick={() => setSelectedItem(null)}
//                 text={'Back'}
//             />
//         );
//     }
//     return 'Connect to a Wallet';
// }
