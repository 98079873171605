import { handleActions } from 'redux-actions';
import {persistReducer} from 'redux-persist';
import localForage from 'localforage';

const initialState = {
    activeTeam: null,
    attackTeam: null,
    attacking: true,
    teamInfo: null,
    rewardAmount: null
};

let teamReducer = handleActions({
    UPDATE_ACTIVE_TEAM: (state, { payload }) => ({
        ...state,
        activeTeam: payload,
    }),

    UPDATE_ATTACK_TEAM: (state, {payload}) => ({
        ...state,
        attackTeam: payload
    }),

    UPDATE_ATTACKING: (state, {payload}) => ({
        ...state,
        attacking: payload
    }),

    SET_TEAM_INFO: (state, { payload }) => ({
        ...state,
        teamInfo: payload
    }),

    SET_REWARD_AMOUNT: (state, { payload }) => ({
        ...state,
        rewardAmount: payload
    }),
    USER_LOGOUT: () => initialState
}, initialState);

teamReducer = persistReducer({
    key: 'team',
    storage: localForage,
    whitelist: ['']
}, teamReducer);

export default teamReducer;
