import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { InfoCircleOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';

import { PrgressBar } from '../ProgressBar';
// import { STAGE_NAMES } from '../../store/modules/Round/constants';

import { STAGE_INFO_LIST } from '../../utils/constants';
import {
    setVisibleRoundHistory
} from '../../store/modules/Round/actions';

import style from './RoundContainer.module.scss';

export default function RoundContainer(props) {
    const dispatch = useDispatch();

    const isConnect = useSelector(state => state.user?.connectWallet?.isConnect);
    const { roundImage } = props;
    const [stakeTime, setStakeTime] = useState('');
    const [percent, setPercent] = useState(0);

    const cycleIndex = useSelector(state => state.round.cycleIndex);
    const cycleInfo = useSelector(state => state.round.cycleInfo);
    const isLoadingNextStep = useSelector(state => state.round.isLoadingNextStep);

    const stageIndex = cycleInfo ? cycleInfo.stageIndex : 0;
    const stageStartDate = cycleInfo ? cycleInfo.stageStart * 1000 : null;
    const stageDuration = cycleInfo ? parseInt(cycleInfo.stageDuration) : 0;
    const stageStartObject = dayjs(stageStartDate);
    const stageEndObjet = stageStartObject.add(stageDuration, 'second');

    const zeroPad = (num, places) => String(num).padStart(places, '0');

    useEffect(() => {
        if (stageStartDate && isConnect) {
            let timer = setInterval(async () => {
                const now = dayjs();
                let diffSeconds = stageEndObjet.diff(now, 'second');

                if (diffSeconds < 0) {
                    setStakeTime('Loading...');
                    diffSeconds = 0;
                } else {
                    const seconds = diffSeconds % 60;
                    const minutes = Math.floor(diffSeconds / 60) % 60;
                    const hours = Math.floor(diffSeconds / 3600) % 24;
                    setStakeTime(`${zeroPad(hours, 2)}H ${zeroPad(minutes, 2)}M ${zeroPad(seconds, 2)}S`);
    
                    // setPercent(Math.floor(diffSeconds * 100 / 3600 / 24));
                    setPercent(Math.floor(diffSeconds / stageDuration * 100));
                }

            }, 1000);

            return () => {
                clearInterval(timer);
            };
        }

    }, [stageStartDate, cycleIndex, stageIndex, stakeTime, cycleInfo, isConnect] );

    const handleShowRoundHistory = () => {
        dispatch(setVisibleRoundHistory(true));
    };

    return (
        <div className={style.roundSection}>
            <div className={style.round}>
                <div className={style.iconWrapper}>
                    <img src={roundImage} className={style.icon} alt={'round_image'}/>
                </div>
                <div className={style.roundBox}>
                    <div className={style.roundTitleProgress}>
                        <div className={style.roundTitle}>
                            <p className={style.roundTitle}>{`ROUND ${cycleIndex} - ${STAGE_INFO_LIST[stageIndex].STAGE_NAMES} day`}</p>

                            <span
                                onClick={() => handleShowRoundHistory()}
                                className={style.iconRoundTitle}><InfoCircleOutlined />
                            </span>
                        </div>
                        <PrgressBar
                            direction="left"
                            classes={style.roundProgress}
                            value={percent}
                            title={stakeTime}
                            startColor={'#e66969'}
                            endColor={'#e64646'}
                            loading={isLoadingNextStep}
                        />
                    </div>
                </div>
            </div>
        </div>

    );
}