import React from 'react';
import {useSelector} from 'react-redux';
import LandscapeModal from './LandscapeModal';

export default function LandscapeModalContainer(props) {
    const {isLandscapeVisible} = useSelector(state => state.app);

    return (
        <LandscapeModal visible={isLandscapeVisible} {...props}/>
    );
}
