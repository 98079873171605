import { handleActions } from 'redux-actions';
import {persistReducer} from 'redux-persist';
import localForage from 'localforage';

const initialState = {
    roundStartDate: null,
    roundType: null,
    cycleIndex: 0,
    cycleInfo: null,
    isLoadingNextStep: false,
    isVisibleRoundHistory: false,
    isRoundHistoryRequesting: false,
    roundHistoryList: [],
    listDuration: []
};

let roundReducer = handleActions({
    SET_ROUND_DATA: (state, { payload }) => ({
        ...state,
        cycleIndex: payload.cycleIndex,
        cycleInfo: payload.cycleInfo
    }),
    SET_LOADING_NEXT_STEP: (state, { payload }) => ({
        ...state,
        isLoadingNextStep: payload
    }),
    SET_VISIBLE_ROUND_HISTORY: (state, { payload }) => ({
        ...state,
        isVisibleRoundHistory: payload
    }),
    SET_ROUND_HISTORY_REQUESTING: (state, { payload }) => ({
        ...state,
        isRoundHistoryRequesting: payload
    }),
    GET_ROUND_HISTORY_LIST: (state, { payload }) => ({
        ...state,
        roundHistoryList: payload
    }),
    GET_LIST_DURATION: (state, { payload }) => ({
        ...state,
        listDuration: payload
    }),
    USER_LOGOUT: () => initialState
}, initialState);

roundReducer = persistReducer({
    key: 'round',
    storage: localForage,
    whitelist: []
}, roundReducer);

export default roundReducer;
