import React from 'react';
import {useSelector} from 'react-redux';
import WrongNetworkModal from './WrongNetworkModal';

export default function WrongNetworkModalContainer(props) {
    const {wrongNetwork} = useSelector(state => state.user);
    const {isMobile, isTablet} = useSelector(state => state.app);
    const isGamePage = window.location.pathname === '/game';

    return (
        <WrongNetworkModal 
            visible={wrongNetwork && isGamePage} 
            isMobile={isMobile}
            isTablet={isTablet}
            {...props}/>
    );
}
