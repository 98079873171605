import { useRef } from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useCallback, useEffect} from 'react';

import {BREAKPOINTS} from './constants';
import { loadApp, setIsDesktop, setIsLandscapeVisible, setIsMobile, setIsTablet } from './actions';

import { PAGE_GAME_PATH } from '../../../router/constants';
import { isLandscapeOrientation } from '../../../utils';

export function UseAppInit() {
    /*
        Basic initialization logic for the module:
        init scripts, event listeners, etc...
    */

    const dispatch = useDispatch();
    const value = useRef(document.body.offsetHeight);

    const {isMobile, isTablet, isDesktop, isLandscapeVisible} = useSelector(state => state.app);

    // RESIZE LOGIC

    const onResize = useCallback(() => {
        if ((window.innerWidth < BREAKPOINTS.MD_MIN) && !isMobile) {
            dispatch(setIsMobile())
        } else if ((window.innerWidth > BREAKPOINTS.SM_MAX) && (window.innerWidth < BREAKPOINTS.LG_MIN) && !isTablet) {
            dispatch(setIsTablet())
        } else if ((window.innerWidth > BREAKPOINTS.MD_MAX) && !isDesktop) {
            dispatch(setIsDesktop())
        }
    }, [dispatch, isMobile, isTablet, isDesktop]);

    useEffect(() => {
        onResize();
        window.addEventListener('resize', onResize);

        return () => {
            window.removeEventListener('resize', onResize);
        }
    }, [onResize]);
    
    const onOrientationChange = useCallback(() => {
        const isMobileSize = window.innerWidth < BREAKPOINTS.MD_MIN;
        const isTableSize = window.innerWidth < BREAKPOINTS.SM_MAX;
        if (isMobileSize || isTableSize) {
            if (
                (!isLandscapeOrientation())
              && window.location.pathname === '/game'
            ) {
                dispatch(setIsLandscapeVisible(true))
            } else {
                dispatch(setIsLandscapeVisible(false))
            }
        } else {
            dispatch(setIsLandscapeVisible(false))
        }
    }, [dispatch, isMobile, isTablet, isDesktop, window.location.pathname])

    useEffect(() => {
        onOrientationChange();
        window.addEventListener('orientationchange', onOrientationChange);

        return () => {
            window.removeEventListener('orientationchange', onOrientationChange);
        }
    }, [onOrientationChange]);

    useEffect(() => {
        if (isLandscapeVisible) return;

        const gamePage = window.location.pathname;

        if (gamePage === PAGE_GAME_PATH) {
            document.body.style.height = '150vh';
            document.body.style.overflowY = 'auto';
            window.scrollTo(0, value.current + 100);
        }
    }, [isLandscapeVisible]);
    
    // LOADER LOGIC

    useEffect(() => {
        dispatch(loadApp());
    }, [dispatch]);
}
