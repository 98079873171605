import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { InfoCircleOutlined } from '@ant-design/icons';
import { isEmpty } from 'lodash';

import { TEAMS_LIST_CHOSEN } from './constants';

import { getTeamString } from '../../../utils';
import { MultiPrgressBar } from '../../../components/MultiProgressBar';

import style from './DogeNation.module.scss';

export default function DogeNation() {
    const [title, setTitle] = useState('');
    const [warTeamChosen, setWarTeamChosen] = useState(null);
    const [victimTeams, setVictimsTeams] = useState([]);
    const [totalVotes, setTotalVotes] = useState(0);

    const cycleInfo = useSelector(state => state.round?.cycleInfo ?? {});
    const cycleIndex = useSelector(state => state.round?.cycleIndex);
    const teamInfo = useSelector(state => state.team?.teamInfo ?? {});

    const stageIndex = cycleInfo ? parseInt(cycleInfo.stageIndex) : 0;
    const warTeam = cycleInfo ? parseInt(cycleInfo.warTeam) : null;
    const isChosenWarTeam = warTeam < 3;

    const votedCountA = !isEmpty(victimTeams) && teamInfo[victimTeams[0].name]?.victimVotedCount;
    const votedCountB = !isEmpty(victimTeams) && teamInfo[victimTeams[1].name]?.victimVotedCount;

    useEffect(() => {
        if (isChosenWarTeam) {
            const warTeamString = getTeamString(warTeam);
            setTitle(`${warTeamString} Nation War Vote`);

            const getWarTeamChosen = TEAMS_LIST_CHOSEN.find(item => item.teamIndex === warTeam);
            const getVictimTeams = TEAMS_LIST_CHOSEN.filter(item => item.teamIndex !== warTeam);
            setWarTeamChosen(getWarTeamChosen);
            setVictimsTeams(getVictimTeams);
        }
    }, [stageIndex, warTeam, cycleIndex]);

    useEffect(() => {
        if (warTeamChosen) {
            const victimA = victimTeams[0];
            const victimB = victimTeams[1];

            const nameVictimA = victimA?.name;
            const nameVictimB = victimB?.name;

            const percentVotingA = teamInfo[nameVictimA]?.victimVotedCount;
            const percentVotingB = teamInfo[nameVictimB]?.victimVotedCount;

            const updateVictimA = {...victimA, percent: parseInt(percentVotingA)};
            const updateVictimB = {...victimB, percent: parseInt(percentVotingB)};

            setVictimsTeams([updateVictimA, updateVictimB]);
            setTotalVotes(parseInt(percentVotingA) + parseInt(percentVotingB));
        }
    }, [votedCountA, votedCountB, stageIndex]);

    const renderVictimTeams = () => (
        victimTeams && victimTeams.map((item, index) => {
            const calcPercent = (totalVotes === 0) ? 0 : Math.round(item?.percent / totalVotes * 100);

            return (
                <div className={style.nationVoteItem} key={`doge_nation_${index}`}>
                    {item.image && (
                        <img src={item.image} alt={'doge'} className={style.nationVoteItemIcon} />
                    )}

                    <div className={style.nationVoteItemContent}>
                        <div className={style.itemTitle}>{item.title}</div>
                        <div className={style.itemPercent}>{calcPercent}%</div>
                    </div>
                </div>
            );
        })
    );

    return (
        <>
            {isChosenWarTeam &&
            <div className={style.container}>
                <img src={warTeamChosen?.image} className={style.nationVoteImage} alt={'nation'}/>

                <div className={style.nationVoteContent}>
                    <div className={style.nationVoteTitle}>{title} <span><InfoCircleOutlined/></span></div>
                    <div className={style.nationVoteVersus}>
                        {renderVictimTeams()}
                    </div>

                    <MultiPrgressBar
                        total={totalVotes}
                        width={'100%'}
                        direction="left"
                        items={victimTeams.map(item => {
                            const percent = totalVotes === 0 ? 0 : Math.round(item.percent / totalVotes * 100);

                            return {
                                value: percent,
                                title: '',
                                startColor: item.startColor,
                                endColor: item.endColor
                            }
                        })}
                    />
                </div>
            </div>
            }
        </>
    )
}