import React from 'react';
import ProfileModal from './ProfileModal';

export default function ProfileModalContainer(props) {
    const {visible} = props;
    return (
        <ProfileModal
            visible={visible}
            {...props}
        />
    );
}
