import React from 'react';
import cn from 'classnames';
import {Col, Divider, Row} from 'antd';
// import {Link} from 'react-router-dom';
// import {PAGE_HOME_PATH} from '../../../router/constants';
import logo from '../../../assets/img/logo.png';
import teleIcon from '../../../assets/img/social/tele.png';
import twitterIcon from '../../../assets/img/social/twitter.png';
import uniswapIcon from '../../../assets/img/social/uniswap.png';
import pancakeIcon from '../../../assets/img/social/pancake.png';

import style from './Footer.module.scss';

export default function Footer() {
    return (
        <footer className={cn(style.footerContainer)}>
            <div className={style.footerWrapper}>
                <Row type="flex" align="middle">
                    <Col xs={12} sm={6} md={6} lg={6} className={style.logoContainer}>
                        <a href="/">
                            <img src={logo} alt="logo" className={style.logo}/>
                        </a>
                    </Col>

                    <Col xs={24} sm={12} md={12} lg={12} className={cn(style.termContainer, style.desktop)}>
                        {/*<Link to={PAGE_HOME_PATH}>*/}
                        {/*    Terms of Service*/}
                        {/*</Link>*/}
                        {/*<Link to={PAGE_HOME_PATH}>*/}
                        {/*    Privacy Policy*/}
                        {/*</Link>*/}
                    </Col>

                    <Col xs={12} sm={6} md={6} lg={6}>
                        <div className={style.socialButtonLabel}>
                            <p>Follow us on</p>
                        </div>
                        <div className={style.socialButton}>
                            <a href="https://t.me/officialmemewars" target={'_blank'} rel={'noreferrer'}>
                                <img src={teleIcon} alt="tele"
                                    className="social"/>
                            </a>
                            <a href="https://twitter.com/MemeWarsCrypto" target={'_blank'} rel={'noreferrer'}>
                                <img src={twitterIcon} alt="twitter"
                                    className="social"/>
                            </a>
                            <a href="https://bscscan.com/token/0x9f28455a82baa6b4923a5e2d7624aaf574182585#balances" target={'_blank'} rel={'noreferrer'}>
                                <img src={uniswapIcon} alt="uniswap"
                                    className="social"/>
                            </a>
                            <a href="https://pancakeswap.finance/swap#/swap?outputCurrency=0x9f28455a82baa6b4923a5e2d7624aaf574182585" target={'_blank'} rel={'noreferrer'}>
                                <img src={pancakeIcon} alt="pancake"
                                    className="social"/>
                            </a>
                        </div>
                    </Col>

                    <Col xs={24} sm={24} md={12} lg={12} className={cn(style.termContainer, style.mobile)}>
                        {/*<Link to={PAGE_HOME_PATH}>*/}
                        {/*    Terms of Service*/}
                        {/*</Link>*/}
                        {/*<Link to={PAGE_HOME_PATH}>*/}
                        {/*    Privacy Policy*/}
                        {/*</Link>*/}
                    </Col>
                </Row>
                <Divider className={style.divider}/>
            </div>
        </footer>
    )
}
