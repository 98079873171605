import React, {useEffect} from 'react';
import lottie from 'lottie-web';
import loadingJson from './data';
import cn from 'classnames';
import gameSharkLogo from './assets/game-shark-logo.png';
import style from './Loader.module.scss';
import { useSelector } from 'react-redux';

export default function Loader(props) {
    const {isVisible} = props;
    const id = 'loading-container';

    const {isMobile} = useSelector(state => state.app);

    useEffect(() => {
        let animation = lottie.loadAnimation({
            container: document.getElementById(id),
            animationData: loadingJson,
            renderer: 'svg',
            loop: true,
            autoplay: false,
            name: 'animation',
        });

        animation.setSpeed(1.3);

        setTimeout(() => {
            animation.play()
        }, 350);

        return () => {
            lottie.destroy('animation');
        }
    }, []);

    return (
        
        <div className={cn(style.container, {
            [style.containerVisible]: isVisible
        })}>
            <div className={style.bgMask} />
            <div className={style.bg}>
                <img
                    src={isMobile ? '/images/bg-watermark-mobile.png' : '/images/bg-watermark.png'}
                    className={style.bgImage}
                    alt="background"
                />
                <div className={style.logoContainer}>
                    <div id={id} className={style.loader}/>
                    {
                        false && (
                            <div className={style.gamesharkWrapper}>
                                <p className={style.poweredBy}>Powered by</p>
                                <img src={gameSharkLogo} alt="logo"
                                    className={style.poweredByIcon}/>
                            </div>
                        )
                    }
                </div>
            </div>
        </div>
    )
}
