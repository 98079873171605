import React from 'react';
import { Radio } from 'antd';

import style from './RadioGroupCustom.module.scss';

export default function RadioGroupCustom(props) {
    const {
        items,
        isVoted,
        activeTeam,
        nationItem
    } = props;

    return (
        <Radio.Group name="radiogroup" className={style.itemList} {...props}>
            {items.map((item) => (
                <div className={style.raidoItem} key={`radio_item_${item.value}`}>
                    <Radio
                        checked={nationItem && isVoted}
                        value={item.value}
                        className={style.radio}
                        disabled={item.value === activeTeam || nationItem && isVoted}
                    >
                        <div className={style.info}>
                            <div>
                                <div className={style.title}>{item.displayName}</div>
                                <div className={style.description}>{item.description}</div>
                            </div>

                            <div className={style.text}>
                                {item.value === activeTeam ? (
                                    <p style={{ color: item.color }}>War</p>
                                ) : (
                                    <p style={{ color: item.color }}>{item.value === nationItem && isVoted && 'Voted'}</p>
                                )}
                            </div>
                        </div>
                    </Radio>

                    <div className={style.radioImage}>
                        <img src={item.image} alt='item_image'/>
                    </div>
                </div>
            ))}
        </Radio.Group>
    )
}
