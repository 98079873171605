import React, {useCallback, useEffect, useState} from 'react';
import cn from 'classnames';
import Button from '../../Button';
import {PAGE_HOME_PATH} from '../../../router/constants';
import {Link} from 'react-router-dom';
import logo from '../../../assets/img/logo.png';
import telegramIcon from '../../../assets/img/social/telegram.svg';

import style from './Header.module.scss';

export default function Header(props) {
    const {
        wrapperClass,
        fullWidth,
        menuOpen,
    } = props;
    const [scrolled, setScrolled] = useState(false);

    const handleScroll = useCallback(() => {
        if (!scrolled && window.scrollY > 1) {
            setScrolled(true);
        } else if (scrolled && window.scrollY < 1) {
            setScrolled(false);
        }
    }, [scrolled]);

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    });

    return (
        <header className={cn(style.container, wrapperClass, {
            [style.containerScrolled]: scrolled,
            [style.open]: menuOpen
        })}>
            <div className={style.fade}/>
            <div className={cn(style.content, {[style.fullWidth]: fullWidth})}>
                <div className={style.left}>
                    <Link className={style.logoLink} to={PAGE_HOME_PATH}>
                        <img src={logo} alt="logo" className={style.logo}/>
                    </Link>
                </div>
                <div className={style.right}>
                    <Button compact wrapperClass={style.joinBtn} onClick={() => window.open( 'https://t.me/officialmemewars')} >
                        <img src={telegramIcon} alt="telegram"
                            className={style.telegramIcon}/>
                        <p>Join Chat</p>
                    </Button>
                </div>
            </div>
        </header>
    );
}
