import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Tooltip } from 'antd';

// import Button from '../Button';
import { VoteModalContainer } from '../VoteModal';

import { setUserInfo } from '../../store/modules/User/actions';
import { setConnectModalVisible } from '../../store/modules/App/actions';
import { contractAddresses, checkIsValidVoteTeam } from '../../utils';
import { getUserInfo } from '../../utils/callHelpers';
import PresenterABI from '../../abi/presenterAbi';

// import {ReactComponent as VoteIcon} from './img/vote.svg';
import voteIcon from './img/vote.svg';

import style from './Vote.module.scss';

export default function VoteContainer() {
    const dispatch = useDispatch();

    const [isVisibleVoteModal, setVisibleVoteModal] = useState(false);
    const [textTooltip, setTextTooltip] = useState('');

    const isConnected = useSelector(state => state.user?.connectWallet?.isConnect);
    const cycleInfo = useSelector(state => state.round?.cycleInfo ?? {});
    const teamsList = useSelector(state => state.user?.userInfo);
    const userAddress = useSelector(state => state.user?.userAccount?.accounts[0] ?? '');
    const chainId = useSelector(state => state.user?.chainId ?? '');

    const stageIndex = cycleInfo ? parseInt(cycleInfo.stageIndex) : 0;
    const warTeam = cycleInfo?.warTeam ?? 0;
    const isValidVoteTeam = warTeam && cycleInfo && checkIsValidVoteTeam(warTeam, teamsList);
    const isNotStakedAnyTeams = isValidVoteTeam?.isNotStakedAnyTeams;
    const isStakedButNotWar = isValidVoteTeam?.isStakedButNotWar;

    useEffect(async () => {
        try {
            if (!isConnected) return;

            const presenterContract = new window.web3.eth.Contract(PresenterABI, contractAddresses(chainId)['PRESENTER']);
            const dogeInfo = await getUserInfo(presenterContract, userAddress, 0);
            const pepeInfo = await getUserInfo(presenterContract, userAddress, 1);
            const wojakInfo = await getUserInfo(presenterContract, userAddress, 2);
    
            dispatch(setUserInfo({ dogeInfo, pepeInfo, wojakInfo }));
        } catch (error) {
            return error;
        }
    }, [stageIndex, userAddress]);

    useEffect(() => {
        if (stageIndex !== 3) {
            setTextTooltip('Cannot vote yet because it is not yet voting day');
        } else if (stageIndex === 3 && isNotStakedAnyTeams) {
            setTextTooltip('You have not staked in any teams yet');
        } else if (stageIndex === 3 && !isNotStakedAnyTeams && !isStakedButNotWar) {
            setTextTooltip('Your team was not chosen to declare war');
        }
    }, [stageIndex, warTeam, isValidVoteTeam])

    const handleModalVisible = () => {
        if (isConnected) {
            setVisibleVoteModal(true);
        } else {
            dispatch(setConnectModalVisible(true));
        }
    };

    const handleModalHidden = () => {
        setVisibleVoteModal(false);
    };

    return (
        <div>
            {stageIndex !== 3 || isNotStakedAnyTeams || !isStakedButNotWar ? (
                <Tooltip
                    overlayClassName={style.tooltip}
                    placement={'topLeft'}
                    title={textTooltip}
                >
                    <button
                        className={style.voteBtnDisable}
                        onClick={() => {}}
                    >
                        <img className={style.voteIcon} src={voteIcon} alt="" />
                        VOTE
                    </button>
                </Tooltip>
            ) : (
                <button
                    className={style.voteBtn}
                    onClick={() => handleModalVisible()}
                >
                    <img className={style.voteIcon} src={voteIcon} alt="" />
                    VOTE
                </button>
            )}
            {/* <Button
                wrapperClass={style.voteBtn}
                text={'VOTE'}
                icon={<VoteIcon />}
                iconClass={style.icon}
                iconWidth={60}
                iconAlignStart
                disabled={stageIndex !== 3 || !isValidVoteTeam}
                onClick={handleModalVisible}
            /> */}
            <VoteModalContainer
                visible={isVisibleVoteModal}
                handleClose={handleModalHidden}
                handleCancel={handleModalHidden}
                closeable={false}
                warTeam={parseInt(warTeam)}
                stageIndex={stageIndex}
            />
        </div>
    );
}
