import {combineReducers} from 'redux';
import {routerReducer} from 'react-router-redux';
import userReducer from './modules/User/reducer';
import appReducer from './modules/App/reducer';
import roundReducer from './modules/Round/reducers';
import teamReducer from './modules/Team/reducers';

const rootReducer = combineReducers({
    app: appReducer,
    user: userReducer,
    router: routerReducer,
    round: roundReducer,
    team: teamReducer
});

export default rootReducer;
