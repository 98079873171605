
import Logger from 'js-logger';
import { get } from 'lodash';

export const CONTRACT_TIMEOUT = 5;
export const ERROR_CODE_TIME_OUT = 408;
export const MESSAGE_TIMEOUT_MINT = 'There was a timeout while attempting to mint your transaction.'

export const getUserInfo = async (presenterContract, address, teamIndex) => {
    return presenterContract.methods.users(address, teamIndex).call();
};

export const getTeamInfo = async (presenterContract, teamIndex) => {
    return presenterContract.methods.teams(teamIndex).call();
};

export const getCycleIndex = async (presentContract) => {
    return presentContract.methods.cycleIndex().call();
};

export const getCycleInfo = async (presentContract, cycleIndex) => {
    return presentContract.methods.cycles(cycleIndex).call();
};

export const getVoteInfo = async (presentContract, cycleIndex, teamIndex, address) => {
    return presentContract.methods.votes(cycleIndex, teamIndex, address).call();
};

export const getStageDurationByIndex = async (presentContract, stageIndex) => {
    return presentContract.methods.getStageDurationByIndex(stageIndex).call();
};

export const deposit = (presentContract, teamIndex, poolIndex, amount, address, cb) => {
    return presentContract.methods.deposit(teamIndex, poolIndex, amount).send({ from: address })
        .on('error', function (error) {
            Logger.log(`deposit error ${error}`);
            cb(null, { ...error, description: error.message });
        })
        .on('transactionHash', (tx) => {
            waitForReceipt(tx, cb, 0);
            return tx.transactionHash;
        });
};

export const getPendingReward = async (presentContract, teamIndex, address) => {
    return presentContract.methods.getPendingReward(address, teamIndex).call();
};

export const claimReward = async (presentContract, teamIndex, address, cb) => {
    return presentContract.methods.claimReward(teamIndex)
        .send({ from: address })
        .on('error', function (error) {
            Logger.log(`claimReward error ${error}`);
            cb(null, { ...error, description: error.message });
        })
        .on('transactionHash', (tx) => {
            waitForReceipt(tx, cb, 0);
            return tx.transactionHash;
        });
};

export const getProductionBySecond = async (presentContract, teamIndex) => {
    return presentContract.methods.getProductionBySecond(teamIndex).call();
};

export const castVoteForVictimTeam = async (presentContract, teamIndex, address) => {
    return presentContract.methods.castVoteForVictimTeam(teamIndex).send({ from: address })
        .on('transactionHash', (tx) => {
            return tx.transactionHash;
        });
};

export const castVoteForVictimTeamCallback = async (presentContract, teamIndex, address, cb) => {
    return presentContract.methods.castVoteForVictimTeam(teamIndex).send({ from: address })
        .on('error', function (error) {
            Logger.log(`castVoteForVictimTeamCallback error ${error}`);
            cb(null, { ...error, description: error.message });
        })
        .on('transactionHash', (tx) => {
            waitForReceipt(tx, cb, 0);
            return tx.transactionHash;
        });
};

export const startProduction = (presentContract, address) => {
    return presentContract.methods.startProduction().send({ from: address })
        .on('transactionHash', (tx) => {
            return tx.transactionHash;
        });
};

export const startRandomWarTeam = (presentContract, address) => {
    return presentContract.methods.startRandomWarTeam().send({ from: address })
        .on('transactionHash', (tx) => {
            return tx.transactionHash;
        });
};

export const randomWarTeamVRF = (presentContract, address) => {
    return presentContract.methods.randomWarTeamVRF().send({ from: address })
        .on('transactionHash', (tx) => {
            return tx.transactionHash;
        });
};

export const startVotingForVictimTeam = (presentContract, address) => {
    return presentContract.methods.startVotingForVictimTeam().send({ from: address })
        .on('transactionHash', (tx) => {
            return tx.transactionHash;
        });
};

export const startWarTimeStage = (presentContract, address) => {
    return presentContract.methods.startWarTimeStage().send({ from: address })
        .on('transactionHash', (tx) => {
            return tx.transactionHash;
        });
};

export const startResultStage = (presentContract, address) => {
    return presentContract.methods.startResultStage().send({ from: address })
        .on('transactionHash', (tx) => {
            return tx.transactionHash;
        });
};

export const startStaking = (presentContract, duration, amount, address) => {
    return presentContract.methods.startStaking(duration, amount).send({ from: address })
        .on('transactionHash', (tx) => {
            return tx.transactionHash;
        });
};

export const SetWarTeam = (presentContract, teamIndex, address) => {
    return presentContract.methods.SetWarTeam(teamIndex).send({ from: address })
        .on('transactionHash', (tx) => {
            return tx.transactionHash;
        })
};

export const approve = (warTokenContract, amount, senderAddress, address, cb) => {
    return warTokenContract.methods.approve(senderAddress, amount).send({ from: address })
        .on('error', function (error) {
            Logger.log(`approve error ${error}`);
            cb(null, { ...error, description: error.message });
        })
        .on('transactionHash', (tx) => {
            waitForReceipt(tx, cb, 0);
            return tx.transactionHash;
        })
}

export const validateStageChangeable = (presentContract, stageIndex) => {
    return presentContract.methods.validateStageChangeable(stageIndex).call();
}

export const getAllowance = async (presentContract, accountAddress, spenderAddress) => {
    return presentContract.methods.allowance(accountAddress, spenderAddress).call();
}


export const waitForReceipt = (hash, cb, timeout) => {
    window.web3 ? window.web3.eth.getTransactionReceipt(hash, function (error, receipt) {
        if (timeout >= (CONTRACT_TIMEOUT * 60 * 1000)) {
            return cb({ code: ERROR_CODE_TIME_OUT, description: MESSAGE_TIMEOUT_MINT });
        }
        if (error) {
            Logger.log(`waitForReceipt ${error}`);
            cb({ ...error, description: error.message });
        }
        if (receipt !== null) {
            Logger.log(`waitForReceipt receipt ${receipt} ${error}`);
            const status = get(receipt, 'status', false);
            // Transaction went through
            if (cb) {
                status ? cb(receipt) : cb(null, null);
            }
        } else {
            // Try again in 1 second
            window.setTimeout(function () {
                waitForReceipt(hash, cb, (timeout + 1000));
            }, 1000);
        }
    }) : '';
}