import { createAction } from 'redux-actions';
import Logger from 'js-logger';
import { contractAddresses, fromWeiDecimals } from '../../../utils';
import { callDataTeamData } from './hooks';
import { getProductionBySecond, getTeamInfo } from '../../../utils/callHelpers';
import PresenterABI from '../../../abi/presenterAbi';

export const updateActiveTeam = createAction('UPDATE_ACTIVE_TEAM');
export const updateAttackTeam = createAction('UPDATE_ATTACK_TEAM');
export const updateAttacking = createAction('UPDATE_ATTACKING');

export const setActiveTeam = (team) => (dispatch) => {
    // TODO: Implement get round data with api

    dispatch(updateActiveTeam(team));
};

export const setAttackTeam = (team) => (dispatch) => {
    dispatch(updateAttackTeam(team));
};

export const isAttacking = (attacking) => (dispatch) => {
    dispatch(updateAttacking(attacking));
};


export const setTeamInfo = createAction('SET_TEAM_INFO');
export const updateTeamInfo = (info) => (dispatch) => {
    dispatch(setTeamInfo(info));
};

export const setRewardAmount = createAction('SET_REWARD_AMOUNT');
export const updateRewardAmount = (info) => (dispatch) => {
    dispatch(setRewardAmount(info));
};

export const getProductionData = async (presenterContract) => {
    try {
        const [dogeInfo, pepeInfo, wojakInfo] = await Promise.all([
            getProductionBySecond(presenterContract, 0),
            getProductionBySecond(presenterContract, 1),
            getProductionBySecond(presenterContract, 2)]
        );

        const total = parseInt(fromWeiDecimals(dogeInfo)) + parseInt(fromWeiDecimals(pepeInfo)) + parseInt(fromWeiDecimals(wojakInfo));

        return {
            total,
            doge: parseFloat(fromWeiDecimals(dogeInfo)),
            pepe: parseFloat(fromWeiDecimals(pepeInfo)),
            wojak: parseFloat(fromWeiDecimals(wojakInfo))
        }
    } catch (err) {
        Logger.error(err)
    }
}

export const getDataTeamInfo = async (presenterContract) => {
    try {
        const [dogeInfo, pepeInfo, wojakInfo] = await Promise.all([
            getTeamInfo(presenterContract, 0),
            getTeamInfo(presenterContract, 1),
            getTeamInfo(presenterContract, 2)]
        );

        const dogeReward = parseFloat(fromWeiDecimals(dogeInfo?.rewardAmount));
        const pepeReward = parseFloat(fromWeiDecimals(pepeInfo?.rewardAmount));
        const wojakReward = parseFloat(fromWeiDecimals(wojakInfo?.rewardAmount));

        const total = dogeReward + pepeReward + wojakReward;
        return {
            total,
            doge: dogeReward,
            pepe: pepeReward,
            wojak: wojakReward
        }

    } catch (err) {
        Logger.error(err)
    }
}

export const getDataTeamInfoAndupdate = () => async (dispatch, getState) => {
    try {
        const account = getState()?.user?.userAccount?.accounts[0];
        const chainId = getState()?.user?.chainId;
        if (account && chainId) {
            const presenterContract = new window.web3.eth.Contract(PresenterABI, contractAddresses(chainId)['PRESENTER']);
            const dataTeamInfo = await callDataTeamData(presenterContract, account);
            dispatch(updateTeamInfo(dataTeamInfo));
        }
    } catch (err) {
        Logger.error(err)
    }
};
