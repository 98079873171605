import React from 'react';
import Modal from '../Modal';
import loading from '../../assets/img/loading.png';

import style from './LandscapeModal.module.scss';

export default function LandscapeModal(props) {
    const { wrapperClass, ...restProps } = props;

    return (
        <Modal
            wrapperClass={wrapperClass}
            title={'Screen does not support!'}
            width={390}
            closable={false}
            {...restProps}
        >
            <img src={loading} alt="" className={style.loading}/>
            <h3 className={style.title}>
              Wrong Orientation
            </h3>
            <p className={style.text}>
              Memewars does not support portrait
            </p>
            <p className={style.text}>
              Please rotate your device to landscape.
            </p>
        </Modal>
    );
}
