import React from 'react';

import { PrgressBar } from '../ProgressBar';

import style from './PointContainer.module.scss';

export default function PointItem(props) {
    const { avatar, ...restProps } = props;

    return (
        <div className={style.point}>
            <div className={style.boxes}>
                <PrgressBar
                    classes={style.pointProgressBar}
                    direction="right"
                    highlight={true}
                    {...restProps}
                />
            </div>
            <div className={style.avatarWrapper}>
                <img src={avatar} className={style.avatar} alt={'doge_avatar'} />
            </div>
        </div>

    )
}