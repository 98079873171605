import metamask from './img/metamask.png';
import walletconnect from './img/walletconnect.png';

export const PROVIDER_TYPES = {
    METAMASK: 'MetaMask',
    WALLETCONNECT: 'WalletConnect',
};

export const CONNECTION_TYPES = {
    metamask: 'metamask',
    walletconnect: 'walletconnect'
};

export const PROVIDER_ITEMS = [
    {
        name: PROVIDER_TYPES.METAMASK,
        connector: CONNECTION_TYPES.metamask,
        description: 'Easy to use browser extension.',
        picture: metamask
    },
    {
        name: PROVIDER_TYPES.WALLETCONNECT,
        connector: CONNECTION_TYPES.walletconnect,
        description: '',
        picture: walletconnect
    }
];

export const LOADING_STATUSES = {
    LOADING: 'loading',
    LOADED: 'loaded',
    ERROR: 'error'
};