import React from 'react';
import { Radio } from 'antd';
import style from './RadioGroup.module.scss';

export default function RadioGroup(props) {
    const { items, activeTeam } = props;

    return (
        <Radio.Group name="radiogroup" className={style.itemList} {...props}>
            {items.map((item) => (
                <div className={style.raidoItem} key={`radio_item_${item.value}`}>
                    <Radio value={item.value} className={style.radio} disabled={item.value === activeTeam}>
                        <div>
                            <div className={style.title}>{item.displayName}</div>
                            <div className={style.description}>
                                {item.isPrefix && <span className={style.prefix}>~</span>}
                                {item.description}
                            </div>
                        </div>
                    </Radio>
                    {item.image &&
                        <div className={style.radioImage}>
                            <img src={item.image} alt='item_image' />
                        </div>
                    }
                </div>
            ))
            }
        </Radio.Group>
    )
}