import dogeImg from '../../../assets/img/maps/doge.png';
import pepeImg from '../../../assets/img/maps/pepe.png';
import wojakImg from '../../../assets/img/maps/wojakSquare.svg';

export const TEAMS_LIST_CHOSEN = [
    {
        teamIndex: 0,
        name: 'doge',
        title: 'DOGE NATION',
        image: dogeImg,
        percent: 0,
        startColor: '#feb540',
        endColor: '#fdc66e'
    },
    {
        teamIndex: 1,
        name: 'pepe',
        title: 'PEPE NATION',
        image: pepeImg,
        percent: 0,
        startColor: '#23a62f',
        endColor: '#62ad69'
    },
    {
        teamIndex: 2,
        name: 'wojak',
        title: 'WOJAK NATION',
        image: wojakImg,
        percent: 0,
        startColor: '#e64646',
        endColor: '#e66969'
    }
];