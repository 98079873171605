import React from 'react'
import style from './NotFound.module.scss';
import PageLayout from '../../components/PageLayout';
import { Result } from 'antd';

export default function NotFound() {
    return (
        <PageLayout>
            <div className={style.container}>
                <Result
                    status="404"
                    title="404"
                    subTitle="Sorry, the page you visited does not exist."
                />
            </div>
        </PageLayout>
    )
}
