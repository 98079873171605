import { CHAIN_MAINNET } from './constants';
import fromExponential from 'from-exponential';
import { detect } from 'detect-browser';
import { BigNumber } from 'bignumber.js';
import { ethers } from 'ethers';
import prettyNum, { ROUNDING_MODE } from 'pretty-num';

import { verifyChainId } from '../store/modules/User/actions';

const browser = detect();

export const LENGTH_TO_FIXED_PRICE = 7;
export const THOUSANDS_SEPARATOR = '.';

export default function formatNumber(value, prefix = '', fixed_amount = 0, thousands_separator = ',') {
    const removeDecimal = value.toString().split(THOUSANDS_SEPARATOR)[0];
    const newValue = normalizeValue(removeDecimal);

    if (isNaN(value)) {
        return 'NaN';
    }

    return prefix + separateThousands(newValue.toFixed(fixed_amount), thousands_separator);
}

function normalizeValue(value) {
    if (typeof value === 'string') {
        return parseFloat(value);
    }

    return value;
}

export function formatNumberExtend(value, prefix = '', fixed_amount = 0, thousands_separator = ',') {
    const newValue = normalizeValue(value);

    if (isNaN(value)) {
        return 'NaN';
    }

    return prefix + separateThousands(newValue.toFixed(fixed_amount), thousands_separator);
}

function separateThousands(x, s) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, s);
}

export function formatNumberFull(value, fixed_amount = LENGTH_TO_FIXED_PRICE, thousands_separator = ',', prefix = '') {
    if (!value) {
        return 0;
    }
    const valueReplace = value.toString().split(thousands_separator).join('');
    let newValueFloat = parseFloatWithDecimal(valueReplace, fixed_amount);
    return prefix + separateThousandsWithFloat(newValueFloat.toString(), thousands_separator);
}

export function parseFloatWithDecimal(value, fixed_amount) {
    if (typeof value === 'string') {
        return fromExponential(parseFloat(parseFloat(value).toFixed(fixed_amount)) || 0);
    }

    return fromExponential(parseFloat(value.toFixed(fixed_amount)) || 0);
}

function separateThousandsWithFloat(value, separator) {
    const arrSplitValue = value.split('.');
    if (arrSplitValue && arrSplitValue.length < 2) {
        return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, separator);
    }
    const firstNumber = arrSplitValue[0].replace(/\B(?=(\d{3})+(?!\d))/g, separator);
    return `${firstNumber}.${arrSplitValue[1]}`;
}

export function formatBlockchainAddress(address, precision = 4, precisionEnd) {
    if (!address) {
        return '';
    }

    return `${address.slice(0, precision + 2)}...${address.slice(precisionEnd ? -precisionEnd : -precision)}`;
}

export function contractAddresses(chainId) {
    if (verifyChainId(chainId) === verifyChainId(CHAIN_MAINNET)) {
        return {
            'WARTOKEN': process.env.REACT_APP_WAR_TOKEN_ADDRESS_MAINNET,
            'PRESENTER': process.env.REACT_APP_PRESENTER_CONTRACT_ADDRES_MAINNET,
        };
    }
    return {
        'WARTOKEN': process.env.REACT_APP_WAR_TOKEN_ADDRESS_TESTNET,
        'PRESENTER': process.env.REACT_APP_PRESENTER_CONTRACT_ADDRES_TESTNET,
    };
}


export const getTeamIndex = (teamString) => {
    switch (teamString) {
    case 'doge':
        return 0;
    case 'pepe':
        return 1;
    case 'wojak':
        return 2;
    }
};

export const getTeamString = (teamIndex) => {
    switch (teamIndex) {
    case 0:
        return 'doge';
    case 1:
        return 'pepe';
    case 2:
        return 'wojak';
    }
};

export const getPoolIndex = (poolString) => {
    switch (poolString) {
    case 'attack':
        return 0;
    case 'defense':
        return 1;
    case 'production':
        return 2;
    }
};

export const getPercent = (value, total) => {
    if (parseInt(total) === 0) {
        return 0;
    } else {
        return parseInt(parseInt(value) * 100 / parseInt(total));
    }
};

export const checkIsValidVoteTeam = (warTeamIndex, teamsList = {}) => {
    const index = parseInt(warTeamIndex);

    if (index > 3) return;
    if (!teamsList) return;

    const teamName = getTeamString(index); // war team

    const totalAmountDoge = Number(teamsList['doge']?.totalAmount);
    const totalAmountPepe = Number(teamsList['pepe']?.totalAmount);
    const totalAmountWojak = Number(teamsList['wojak']?.totalAmount);

    const data = [
        { amount: totalAmountDoge, name: 'doge' },
        { amount: totalAmountPepe, name: 'pepe' },
        { amount: totalAmountWojak, name: 'wojak'}
    ];

    const filterData = data.map(e => {
        if (e.amount > 0) return e.name;
    });

    const isNotStakedAnyTeams = totalAmountDoge === 0 && totalAmountPepe === 0 && totalAmountWojak === 0;
    const isStakedButNotWar = filterData.includes(teamName);

    return { isNotStakedAnyTeams, isStakedButNotWar }
};

export const isStakingDay = (stageIndex) => {
    return stageIndex === 0;
};

export const capitalizeFirstChar = (word) => {
    if (!word) return;
    return (word[0] ?? '').toUpperCase() + (word ?? '').slice(1).toLowerCase();
};

export const getQueryParam = (name, defaultData = '') => {
    const q = window.location.search.match(new RegExp('[?&]' + name + '=([^&#]*)'));
    return q ? q[1] : defaultData;
};

export const validateEmail = email => {
    return typeof email === 'string' && /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
};

export const isLandscapeOrientation = () => {
    if (browser.os.toLowerCase() === 'ios' || browser.os.toLowerCase().includes('android')) {
        return Math.abs(window.orientation) === 90;
    }

    return !window.matchMedia('(orientation: portrait)').matches;
};

export const onFocusHandle = () => {
    let viewheight = window.screen.height;
    let viewwidth = window.screen.width;
    let viewport = document.querySelector('meta[name=viewport]');
    viewport.setAttribute('content', 'height=' + viewheight + 'px, width=' + viewwidth + 'px, initial-scale=1.0');
}

export const onBlurHandle = () => {
    let viewport = document.querySelector('meta[name=viewport]');
    viewport.setAttribute('content', 'height=' + null + 'px, width=' + null + 'px, initial-scale=1.0');
}

export const ua = window.navigator.userAgent;
export const iOS = !!ua.match(/iPad/i) || !!ua.match(/iPhone/i);
export const isChrome = navigator.userAgent.indexOf('Chrome') !== -1;

// // 1 => 1000000000000000000
export const toWeiDecimals = (amount, decimals = 18) => {
    return fromExponential(Number(new BigNumber(amount * 10 ** decimals)));
}

// 1e18 => 1
export const fromWeiDecimals = (amount, decimals = 18) => {
    amount = BigNumber.isBigNumber(amount) ? amount.toNumber() : amount;
    return Number(ethers.utils.formatUnits(prettyNum(fromExponential(amount || 0), {
        precision: decimals,
        roundingMode: ROUNDING_MODE.DOWN
    }), decimals));
};
