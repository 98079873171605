import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import PresenterABI from '../../../abi/presenterAbi';
import { contractAddresses } from '../../../utils/index';
import { getRoundHistoryList } from '../../../store/modules/Round/actions';
import { getRoundHistoriesList } from '../../../store/modules/Round/utils';

function useRoundHistory(props) {
    const dispatch = useDispatch();

    const { load, setLoad } = props;

    const [isLoading, setLoading] = useState(false);
    const [isError, setError] = useState(false);
    // const [pivot, setPivot] = useState(0);

    const chainId = useSelector(state => state.user?.chainId);
    const roundHistoryList = useSelector(state => state.round?.roundHistoryList?.response);
    const roundIndexList = useSelector(state => state.round?.roundHistoryList?.roundIndex);
    const currentLastItem = useSelector(state => state.round?.roundHistoryList?.lastItem);
    const cycleIndex = useSelector(state => state.round?.cycleIndex);

    const addresses = contractAddresses(chainId);

    useEffect(async () => {
        // if (pivot > 0) return;

        try {
            const {
                response,
                roundIndex,
                offset,
                lastItem
            } = await getRoundHistoriesList(
                cycleIndex,
                9,
                PresenterABI,
                addresses['PRESENTER']
            );
    
            const result = {
                response,
                roundIndex,
                offset,
                lastItem
            }
    
            dispatch(getRoundHistoryList(result));
        } catch (error) {
            return error;
        }
    }, [cycleIndex]);

    useEffect(async () => {
        if (!load) return;
        if (currentLastItem <= 1) return;

        try {
            setLoading(true);
            setError(false);

            const { response, roundIndex, offset, lastItem } = await getRoundHistoriesList(
                currentLastItem,
                10,
                PresenterABI,
                addresses['PRESENTER']
            );

            const result = {
                response: [...roundHistoryList, ...response],
                roundIndex: [...roundIndexList, ...roundIndex],
                offset,
                lastItem
            };

            dispatch(getRoundHistoryList(result));
            // setPivot(5);
            setLoading(false);
            setLoad(false);
        } catch (error) {
            setLoading(false);
            setError(true);
            setLoad(false)

            return error;
        }
    }, [load]);

    return {
        isError,
        isLoading
    };
}

export default useRoundHistory;