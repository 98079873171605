import {useEffect} from 'react';
import {
    connectMetaMask, connectToWalletConnect
} from './actions';
import { useDispatch, useSelector } from 'react-redux';
import { CONNECTION_TYPES } from '../../../components/ConnectToWalletModal/constants';

export function UseUserInit() {
    /*
        Basic initialization logic for the module:
        init scripts, event listeners, etc...
    */

    const dispatch = useDispatch();
    const { isMobile } = useSelector(state => state.app);
    // Init wallet

    useEffect(() => {
        dispatch(connectWallet(isMobile));
    }, [dispatch]);
}

export const connectWallet = (isMobile) => dispatch => {
    const connectorId = window.localStorage.getItem('connectorId');
    if (connectorId === CONNECTION_TYPES.metamask) {
        dispatch(connectMetaMask(isMobile));
    } else if (connectorId === CONNECTION_TYPES.walletconnect) {
        dispatch(connectToWalletConnect());
    }
}

export const autoConnectWallet = (isMobile) => dispatch => {
    const connectorId = window.localStorage.getItem('connectorId');
    if (connectorId === CONNECTION_TYPES.metamask) {
        dispatch(connectMetaMask(isMobile, false));
    }
}