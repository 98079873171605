import { createAction } from 'redux-actions';

export const setCycleInfo = createAction('SET_ROUND_DATA');
export const setLoadingNextStep = createAction('SET_LOADING_NEXT_STEP');
export const setVisibleRoundHistory = createAction('SET_VISIBLE_ROUND_HISTORY');
export const setRoundHistoryRequesting = createAction('SET_ROUND_HISTORY_REQUESTING');
export const getRoundHistoryList = createAction('GET_ROUND_HISTORY_LIST');
export const getListDuration = createAction('GET_LIST_DURATION');

export const updateCycleInfo = (cycleIndex, cycleInfo) => (dispatch) => {
    dispatch(setCycleInfo({cycleIndex, cycleInfo}));
};