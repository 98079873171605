import http from '../../../services/http';

export default class User {
    static fetchWalletInfo(address) {
    // TODO: UNCOMMENT WHEN API IS READY
        return http.get(`/wallet/${address}`);
    }

    static sendVerifyEmail(body) {
        return http.post('/wallet/send-verify-email', body);
    }

    static checkVerifyEmail(body) {
        return http.post('/wallet/check-verify-email', body);
    }

    static registerNotify(body) {
        return http.post('/wallet/notify', body);
    }
}
