export const DEFAULT_POINT_DATA = {
    doge: {
        value: '0%',
        title: '0 MWAR',
        startColor: '#fdc66e',
        endColor: '#ffb946',
        color: '#ffffff'
    },
    wojak: {
        value: '0%',
        title: '0 MWAR',
        startColor: '#e34545',
        endColor: '#d55e5e',
        color: '#ffffff'
    },
    pepe: {
        value: '0%',
        title: '0 MWAR',
        startColor: '#21962b',
        endColor: '#509a58',
        color: '#ffffff'
    }
}

export const INTERVAL_STEP = {
    BLANK : '',
    INTERVAL: 'INTERVAL',
    STOP: 'STOP'
}

export const INTERVAL_TIME = 3;