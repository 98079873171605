import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import cn from 'classnames';

import { HeaderContainer } from './Header';
import ConnectToWalletModalContainer from '../ConnectToWallet';
import { PAGE_GAME_PATH } from '../../router/constants';

import { setConnectModalVisible } from '../../store/modules/App/actions';

import style from './Layout.module.scss';

export default function Layout(props) {
    const dispatch = useDispatch();
    const history = useHistory();

    const { pathname = '/' } = history.location;

    const { connectModalVisible } = useSelector(state => state.app);
    const isConnected = useSelector(state => state.user?.connectWallet?.isConnect);

    return (
        <div id="gamePage" className={cn(style.container, props.wrapperClass)}>
            <HeaderContainer/>

            <main className={style.main}>{props.children}</main>

            {pathname === PAGE_GAME_PATH && (
                <ConnectToWalletModalContainer
                    visible={!isConnected || connectModalVisible}
                    onCancel={() => dispatch(setConnectModalVisible(false))}
                />
            )}
        </div>
    )
}
