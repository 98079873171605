import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';

import {
    updateCycleInfo,
    setLoadingNextStep,
    // getRoundHistoryList,
    getListDuration
} from './actions';
// import { getRoundHistoriesList } from './utils';

import PresenterABI from '../../../abi/presenterAbi';
import { contractAddresses } from '../../../utils';
import {
    getCycleIndex,
    getCycleInfo,
    getStageDurationByIndex
} from '../../../utils/callHelpers';

import dayjs from 'dayjs';

export const UseCycleHooks = () => {
    const dispatch = useDispatch();

    const chainId = useSelector(state => state.user.chainId);
    const account = useSelector(state => state.user.userAccount.accounts)[0];
    const isConnect = useSelector(state => state.user?.connectWallet?.isConnect);
    const cycleIndex = useSelector(state => state.round?.cycleIndex);

    const addresses = contractAddresses(chainId);

    const sleep = (ms) => {
        return new Promise(resolve => setTimeout(resolve, ms));
    };

    const getNewCycleInfo = async () => {
        try {
            dispatch(setLoadingNextStep(false));

            const presenterContract = new window.web3.eth.Contract(PresenterABI, addresses['PRESENTER']);
            const cycleIndex = await getCycleIndex(presenterContract);
            const cycleInfo = await getCycleInfo(presenterContract, cycleIndex);
            const stageDuration = await getStageDurationByIndex(presenterContract, cycleInfo.stageIndex);

            dispatch(updateCycleInfo(cycleIndex, {
                ...cycleInfo,
                stageDuration: stageDuration
            }));
    
            const now = dayjs();
            const stageStartObject = dayjs(Number(cycleInfo.stageStart) * 1000);
            const stageEndObjet = stageStartObject.add(stageDuration, 'second');

            let diffSeconds = stageEndObjet.diff(now, 'second');

            if (diffSeconds > 0) {
                await sleep((diffSeconds + 1) * 1000);
            } else {
                dispatch(setLoadingNextStep(true));
                await sleep(3000);
            }
    
            getNewCycleInfo().then().catch();
        } catch (error) {
            dispatch(setLoadingNextStep(false));
            return error;
        }
    };

    useEffect(async () => {
        if (chainId && account && window.web3) {
            getNewCycleInfo().then().catch();
        }
    }, [chainId, account, isConnect, dispatch]);

    // useEffect(async () => {
    //     const {
    //         response,
    //         roundIndex,
    //         offset,
    //         lastItem
    //     } = await getRoundHistoriesList(
    //         cycleIndex,
    //         0,
    //         PresenterABI,
    //         addresses['PRESENTER']
    //     );

    //     const result = {
    //         response,
    //         roundIndex,
    //         offset,
    //         lastItem
    //     }

    //     dispatch(getRoundHistoryList(result));
    // }, [cycleIndex]);

    useEffect(async () => {
        try {
            const presenterContract = new window.web3.eth.Contract(PresenterABI, addresses['PRESENTER']);

            const listDuration = await Promise.all([
                getStageDurationByIndex(presenterContract, '0'),
                getStageDurationByIndex(presenterContract, '1'),
                getStageDurationByIndex(presenterContract, '2'),
                getStageDurationByIndex(presenterContract, '3'),
                getStageDurationByIndex(presenterContract, '4'),
                getStageDurationByIndex(presenterContract, '5')
            ]);
    
            dispatch(getListDuration(listDuration));
        } catch (error) {
            return error;
        }
    }, [cycleIndex])
};