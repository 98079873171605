import React from 'react';
import cn from 'classnames';

import style from './MultiProgressBar.module.scss';

export const MultiPrgressBar = (props) => {
    const {items, width, direction, classes} = props;
    return (
        <div className={cn(style.container, classes)} style={{width: `${width}`}}>
            {direction === 'left' && items.map((item, index) => (
                <div key={`${index}`} style={{
                    width: `calc(${item.value}%)`, height: '100%',
                    backgroundImage: `linear-gradient(to top, ${item.startColor}, ${item.endColor})`,
                    zIndex: `${100 - index}`,
                    display: item.value === 0 && 'none'
                }} className={style.itemLeft}>
                    {item.title}
                </div>
            ))}

            {direction === 'right' && items.map((item, index) => (
                <div key={`${index}`} style={{
                    width: `calc(${item.value}%)`, height: '100%', color: `${item.color}`,
                    backgroundImage: `linear-gradient(to top, ${item.startColor}, ${item.endColor})`,
                    zIndex: `${100 + index}`,
                    textAlign: 'right',
                    display: item.value === 0 && 'none'
                }} className={style.itemRight}>
                    {item.title}
                </div>
            ))}
        </div>
    )
};