import React from 'react';
import { useSelector } from 'react-redux';

import MapRegions from './MapRegion';
import coinImage from './img/coin.png';
import worldMap from './img/worldmap.svg';
import dogeImage from './img/doge.png';

import VoteContainer from '../Vote';
import StakeContainer from '../Stake';
import NationInfo from '../NationInfo';
import RoundContainer from '../RoundContainer';
import PointContainer from '../PointContainer';

import DogeNation from '../../pages/GamePlay/DogeNation';
import News from '../../pages/GamePlay/News';

import style from './Maps.module.scss';

export const Maps = () => {
    const isConnect = useSelector(state => state.user?.connectWallet?.isConnect);

    return (
        <div className={style.container}>
            <div style={{zIndex: 999}}>
                {isConnect && <News title='News' icon={worldMap} />}
                {isConnect && <DogeNation />}
            </div>
            <div className={style.pointContainer}>
                <RoundContainer roundImage={coinImage} />
                <PointContainer />
            </div>
            <div className={style.maps}>
                <MapRegions/>
            </div>
            <div className={style.bottomButtonsContainer}>
                <VoteContainer />
                <StakeContainer />
            </div>
            <NationInfo nationImg={dogeImage} nationName={'DOGE NATION'} />
        </div>
    )
};