import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { BigNumber } from 'bignumber.js';

import coinImg from '../../../../Maps/img/coin.png';
import style from '../RoundHistoryItem/RoundHistoryItem.module.scss';
import { getDurations, getTimeCycles, prepareDataForRender } from '../utils';

function RoundHistoryItem(props) {
    const { item = {}, roundIndex } = props;

    const [time, setTime] = useState({});

    useEffect(() => {
        const time = getTimeCycles({
            stageIndex: Number(item?.stageIndex),
            stageStartTime: Number(item?.stageStart),
            victimTeam: new BigNumber(item?.victimTeam).toNumber(),
            durations: getDurations(Number(item?.['0']))
        });
        setTime(time);
    }, [item]);

    const heading = (nameStage, beginDay, endDay) => (
        <section className={style.right}>
            <div className={style.top}>
                <h2 className={style.titleNoLive}>ROUND {roundIndex} - {nameStage}</h2>
            </div>

            <p className={style.bottom}>Begins {moment.utc(beginDay).format('MM/DD/YY HH:mm UTC')}, Ends {moment.utc(endDay).format('MM/DD/YY HH:mm UTC')}</p>
        </section>
    );

    const stakingDay = (key, startTime, endTime) => {
        return (
            <div key={key} className={style.item}>
                <div className={style.images}>
                    <img src={coinImg} alt="" />
                </div>

                {heading('STAKING DAY', startTime, endTime)}
            </div>
        )
    };

    const productionDay = (key, startTime, endTime) => {
        return (
            <div key={key} className={style.item}>
                <div className={style.images}>
                    <img src={coinImg} alt="" />
                </div>

                {heading('PRODUCTION DAY', startTime, endTime)}
            </div>
        )
    }

    const randomDay = (key, startTime, endTime) => {
        return (
            <div key={key} className={style.item}>
                <div className={style.images}>
                    <img src={coinImg} alt="" />
                </div>

                {heading('RANDOM DAY', startTime, endTime)}
            </div>
        )
    };

    const votingDay = (key, startTime, endTime) => {
        return (
            <div key={key} className={style.item}>
                <div className={style.images}>
                    <img src={coinImg} alt="" />
                </div>

                {heading('VOTING DAY', startTime, endTime)}
            </div>
        )
    };

    const battleDay = (key, startTime, endTime) => {
        return (
            <div key={key} className={style.item}>
                <div className={style.images}>
                    <img src={coinImg} alt="" />
                </div>

                {heading('BATTLE DAY', startTime, endTime)}
            </div>
        )
    };

    const resultDay = (key, startTime, endTime) => {
        return (
            <div key={key} className={style.item}>
                <div className={style.images}>
                    <img src={coinImg} alt="" />
                </div>

                {heading('RESULT DAY', startTime, endTime)}
            </div>
        )
    };

    const dataRender = prepareDataForRender(time, {
        staking: stakingDay,
        production: productionDay,
        random: randomDay,
        voting: votingDay,
        war: battleDay,
        result: resultDay,
    });

    return (
        <div className={style.container}>
            {dataRender.map((item, i) => item.render(i, item.starTime, item.endTime))}
        </div>
    );
}

export default RoundHistoryItem;
