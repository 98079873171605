import React, { useEffect, useState } from 'react';
import lottie from 'lottie-web';

import Modal from '../../Modal';
import { capitalizeFirstChar } from '../../../utils';

import style from './WarBeginPopup.module.scss';

function WarBeginPopup(props) {
    const { visible, activeTeam, attackTeam, onEnd } = props;

    const [versusInfo, serVersusInfo] = useState('');

    useEffect(async () => {
        let dataJson = null;

        if (activeTeam && attackTeam) {
            if (activeTeam === 'doge' && attackTeam === 'pepe') {
                dataJson = await import('./voteTeamAnimation/DogePepe/vote_doge_pepe');
            } else if (activeTeam === 'doge' && attackTeam === 'wojak') {
                dataJson = await import('./voteTeamAnimation/DogeWojak/vote_doge_wojak');
            } else if (activeTeam === 'pepe' && attackTeam === 'doge') {
                dataJson = await import('./voteTeamAnimation/PepeDoge/vote_pepe_doge');
            } else if (activeTeam === 'pepe' && attackTeam === 'wojak') {
                dataJson = await import('./voteTeamAnimation/PepeWojak/vote_pepe_wojak');
            } else if (activeTeam === 'wojak' && attackTeam === 'doge') {
                dataJson = await import('./voteTeamAnimation/WojakDoge/vote_wojak_doge');
            } else if (activeTeam === 'wojak' && attackTeam === 'pepe') {
                dataJson = await import('./voteTeamAnimation/WojakPepe/vote_wojak_pepe');
            }

            if (dataJson) {
                lottie.destroy('vote-team');
                serVersusInfo(`Attacking ${capitalizeFirstChar(attackTeam)} Nation`);

                let attackAnimation = lottie.loadAnimation({
                    container: document.getElementById('vote-team'),
                    animationData: dataJson,
                    // renderer: 'svg',
                    loop: 1,
                    autoplay: true,
                    name: 'vote-team'
                });
    
                attackAnimation.play();
    
                attackAnimation.addEventListener('complete', () => {
                    onEnd(false);
                    attackAnimation.destroy('vote-team');
                });

                return () => {
                    attackAnimation.removeEventListener('complete');
                }
            }
        }

        return () => {
            lottie.destroy('vote-team');
        }
    }, [activeTeam, attackTeam]);

    return (
        <Modal
            wrapperClass={style.container}
            simple
            width={'390rem'}
            visible={visible}
            closeable={false}
        >
            <div id="vote-team" />
            <div className={style.des}>{versusInfo}</div>
        </Modal>
    );
}

export default WarBeginPopup;