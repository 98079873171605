import React from 'react';
import style from './StakeModal.module.scss';
import Button from '../Button';
import NumberInput from '../NumberInput';
import formatNumber, { onFocusHandle, onBlurHandle } from '../../utils';

export default function StakeAmount(props) {
    const { balance, amount, onChangeAmount } = props;
    return (
        <div className={style.stakeAmount}>
            <div className={style.stake}>
                <div className={style.title}>Stake</div>
                <div className={style.title}>Balance: {formatNumber(balance)}</div>
            </div>
            <div className={style.balance}>
                <div className={style.amount}>
                    <NumberInput value={amount} wrapperClass={style.amountInput}
                        type={'float'}
                        onFocus={onFocusHandle}
                        onBlur={onBlurHandle}
                        onChange={(val) => {
                            onChangeAmount(val);
                        }} />
                    <Button wrapperClass={style.maxBtn} onClick={() => {
                        onChangeAmount(formatNumber(balance));
                    }}>MAX</Button>
                </div>
                <div className={style.amount}>
                    <div className={style.avatar} />MWAR
                </div>
            </div>
        </div>
    )
}