import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { get, orderBy, filter } from 'lodash';
import CloseIcon from '../Modal/CloseIcon';
import Jazzicon, { jsNumberForAddress } from 'react-jazzicon';

import Modal from '../Modal';
import Button from '../Button';
import { clearTransactionLog } from '../../store/modules/User/actions';
import CopyToClipboard from '../CopyToClipboard';
import copyIcon from './assets/copy.png';
import viewIcon from './assets/view.svg';
import checkIcon from './assets/check.png';
import failed from './assets/failed.png';
import transactionViewIcon from './assets/transaction_view.png';

import style from './ProfileModal.module.scss';
import { formatBlockchainAddress } from '../../utils';

const BLOCK_EXPLORER_URL_MAINNET = process.env.REACT_APP_BLOCK_EXPLORER_URL_MAINNET;
const BLOCK_EXPLORER_URL_TESTNET = process.env.REACT_APP_BLOCK_EXPLORER_URL_TESTNET;

export default function ProfileModal(props) {
    const { onCancel, visible, ...restProps } = props;
    const dispatch = useDispatch();
    const address = useSelector(state => state.user.userAccount.accounts ? state.user.userAccount.accounts[0] : '');
    const chainId = useSelector(state => state.user.chainId);
    const etherscanUrl = chainId === '0x38' ? `${BLOCK_EXPLORER_URL_MAINNET}/address/${address}` : `${BLOCK_EXPLORER_URL_TESTNET}/address/${address}`;

    const transactionLogs = useSelector(state => state.user.transactionLogs);
    const logData = filter(transactionLogs.result, item => {
        return item.chainId === chainId && get(item, 'account', '').toLowerCase() === (address || '').toLowerCase();
    });
    const recentlyLogs = orderBy(logData, ['date'], ['desc']).slice(0, 20);

    useEffect(() => {
        const body = document.querySelector('#transactionList');
        body?.scrollTo(0,0)
    }, [visible])

    return (
        <Modal
            wrapperClass={style.container}
            simple
            width={'390rem'}
            handleCancel={onCancel}
            visible={visible}
            {...restProps}
        >
            <div className={style.header}>
                <h2 className={style.heading}>
                    Profile
                </h2>

                <div onClick={() => onCancel()} className={style.closeIcon}>
                    <CloseIcon />
                </div>
            </div>

            <div className={style.data}>
                <div className={style.label}>
                    Connected with Metamask
                </div>

                <div className={style.address}>
                    {/* <img src={addressIcon} alt="address" className={style.icon} /> */}
                    <div className={style.icon}>
                        <Jazzicon diameter={21} seed={jsNumberForAddress(address || '')} />
                    </div>
                    {formatBlockchainAddress(address)}
                </div>

                <div className={style.dataActions}>
                    <CopyToClipboard
                        buttonClass={style.dataAction}
                        buttonText={'Copy Address'}
                        text={address}
                        icon={copyIcon}
                        iconClass={style.dataActionIcon}
                    />

                    <Button
                        wrapperClass={style.dataAction}
                        text={'View on BscScan'}
                        icon={viewIcon}
                        iconAlignStart
                        iconClass={style.dataActionIcon}
                        onClick={() => window.open(etherscanUrl, '_blank')}
                    />
                </div>
            </div>

            <div className={style.transaction}>
                <h4 className={style.transactionHead}>
                    Recent Transactions
                    <Button
                        wrapperClass={style.clearButton}
                        text={'Clear All'}
                        onClick={() => dispatch(clearTransactionLog(address, chainId))}
                    />
                </h4>

                {recentlyLogs && recentlyLogs.length ? (
                    <ul className={style.transactionList} id={'transactionList'}>
                        {recentlyLogs.map((item, i) => (
                            <li className={style.transactionItem} key={i}>
                                <div className={style.transactionName}>
                                    {item.type}
                                    <a key={i} href={chainId === '0x38' ? `${BLOCK_EXPLORER_URL_MAINNET}/tx/${item?.transactionHash}`
                                        : `${BLOCK_EXPLORER_URL_TESTNET}/tx/${item?.transactionHash}`} target="_blank" rel="noopener noreferrer">
                                        <img src={transactionViewIcon} alt="" className={style.transactionViewIcon} />
                                    </a>
                                </div>
                                {item.isError ? (
                                    <img src={failed} alt="" style={{ width: '17rem' }} className={style.transactionViewIcon} />
                                ) : (
                                    <img src={checkIcon} alt="" className={style.checkIcon} />
                                )}
                            </li>
                        ))}
                    </ul>
                ) : (
                    <p className={style.noRecent}>Have No Recent Transactions</p>
                )}
            </div>
        </Modal>
    );
}
