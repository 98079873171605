import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { notification } from 'antd';
import Web3 from 'web3';
import moment from 'moment';
import lottie from 'lottie-web';

import Modal from '../Modal';
import Button from '../Button';
import RadioGroup from '../RadioGroup';
import Switch from '../Switch';
import StakeAmount from './StakeAmount';
import { isAttacking, setAttackTeam } from '../../store/modules/Team/actions';
import { storeTransactionLog, getDataUserInfoAndUpdate } from '../../store/modules/User/actions';
import { approve, deposit, claimReward, getAllowance, ERROR_CODE_TIME_OUT } from '../../utils/callHelpers'; //getTeamInfo
import formatNumber, { contractAddresses, getPoolIndex, getTeamIndex, isStakingDay } from '../../utils';
import PresenterABI from '../../abi/presenterAbi';
import WarTokenABI from '../../abi/warTokenAbi';
import {
    TAB_STAKE_POP_UP, ID_LOADING_STAKE,
    isNewStake, isReward, isMyStake, isRewardZero, LINK_BUY,
    getNationList, getNationListReward, getPoolList
} from './constants';
import { TEAMS } from '../../utils/constants';
import { getDataTeamInfoAndupdate } from '../../store/modules/Team/actions';
import { refeshBalance } from '../../store/modules/User/actions';

import coinImage from './assets/coin.png';
import style from './StakeModal.module.scss';

const STEP = {
    NATION: 'nation',
    POOL: 'pool',
    AMOUNT: 'amount',
    STAKING: 'staking',
    CLAIMING: 'Claiming'
}
const MAX_INT = '0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff';

export default function StakeModal(props) {
    const { handleClose, stageIndex, handleCancel, visible, isMobile, isTablet } = props;
    const dispatch = useDispatch();

    const [step, setStep] = useState(STEP.NATION);
    const [isSuccess, setIsSuccess] = useState(false);
    const [nationTeam, setNationTeam] = useState(null);
    const [nationItem, setNationItem] = useState(null);
    const [poolItem, setPoolItem] = useState(null);
    const [selectedOption, updateSelectedOption] = useState(TAB_STAKE_POP_UP[0]);
    const [amount, setAmount] = useState(0);
    const [isLoading, setIsLoading] = useState(false);

    const chainId = useSelector(state => state.user.chainId);
    const account = useSelector(state => state.user.userAccount.accounts)[0];
    const teamInfo = useSelector(state => state.team.teamInfo);
    const userInfo = useSelector(state => state.user.userInfo);

    const tokenBalance = useSelector(state => state.user.userAccount.tokenBalance);
    const addresses = contractAddresses(chainId);

    const [avatar, setAvatar] = useState(coinImage);
    const [isShowErrorBalance, setIsShowErrorBalance] = useState(false);

    let stakeAnimation = null;
    useEffect(async () => {
        if (step === STEP.STAKING || step === STEP.CLAIMING) {
            const stakeLoadingData = await import('./animation/stake.json');
            stakeAnimation = lottie.loadAnimation({
                container: document.getElementById(ID_LOADING_STAKE),
                animationData: stakeLoadingData,
                renderer: 'svg',
                loop: true,
                autoplay: true,
                name: 'stake-animation'
            });
            stakeAnimation.play();
        }

        if (nationItem === TEAMS.DOGE.CODE) {
            setAvatar(TEAMS.DOGE.IMG);
        } else if (nationItem === TEAMS.PEPE.CODE) {
            setAvatar(TEAMS.PEPE.IMG);
        } else if (nationItem === TEAMS.WOJAK.CODE) {
            setAvatar(TEAMS.WOJAK.IMG);
        }

        if (visible) {
            dispatch(refeshBalance());
            dispatch(getDataTeamInfoAndupdate());
        }
    }, [step, nationItem, visible]);

    const nationList = getNationList(teamInfo, userInfo, selectedOption);

    const nationListReward = getNationListReward(teamInfo, userInfo, selectedOption);

    const poolList = getPoolList(nationTeam);


    /**
     * HANDLE STEP
     * CHECK & APPROVE
     * @param {*} step 
     */
    const handleStep = async (step) => {

        if (step === STEP.STAKING) {
            setStep(step);
            setIsLoading(true);

            const warTokenContract = new window.web3.eth.Contract(WarTokenABI, addresses['WARTOKEN']);
            let amountApprove = String(amount).toString().replace(/,/g, '');

            // CHECK APPROVE 
            let isApprove = true;
            isApprove = await getAllowance(warTokenContract, account, addresses['PRESENTER'])
                .then(async response => {
                    const allowance = BigInt(response || 0);
                    const amount = BigInt(amountApprove);
                    return allowance < amount;
                }).catch(() => {
                    return true
                });

            // APROOVE
            if (isApprove) {
                try {
                    approve(warTokenContract, MAX_INT, addresses['PRESENTER'], account, handleApprove);
                } catch (e) {
                    handleApprove(null, e);
                }
                return;
            }

            // DEPOSIT
            depositProcess();
        }

        if (step === STEP.NATION) {
            setPoolItem(null);
        }
        setStep(step);
    };

    const handleApprove = (receipt, error = null) => {
        const log = {
            date: moment().valueOf(),
            type: 'Approve',
            account,
            chainId
        };

        // ERROR
        if (error) {
            // TIMEOUT
            if (error?.code === ERROR_CODE_TIME_OUT) {
                notification.error({
                    message: 'Approve Timeout',
                    description: error?.message
                });
                setStep(STEP.AMOUNT);
                setIsLoading(false);
                return;
            }

            // FAILED
            if (error?.receipt?.transactionHash) {
                log.isError = true;
                log.transactionHash = error?.receipt?.transactionHash;
                dispatch(storeTransactionLog(log));
                notification.error({
                    message: 'Approve Failed',
                    description: error?.message
                });
                setStep(STEP.AMOUNT);
                setIsLoading(false);
                return;
            }

            // Reject
            notification.error({
                message: 'Approve Failed'
            });
            setStep(STEP.AMOUNT);
            setIsLoading(false);
            return;
        }


        // SUCCESS
        if (receipt && receipt?.transactionHash) {
            log.isError = false;
            log.transactionHash = receipt?.transactionHash;
            dispatch(storeTransactionLog(log));
            depositProcess();
        }
    }

    /**
     * CALL DEPOSIT CONTRACT
     */
    const depositProcess = () => {
        const presenterContract = new window.web3.eth.Contract(PresenterABI, addresses['PRESENTER']);

        const teamIndex = getTeamIndex(nationItem);
        const poolIndex = getPoolIndex(poolItem);
        let amountApprove = String(amount).toString().replace(/,/g, '');

        try {
            deposit(presenterContract, teamIndex, poolIndex, Web3.utils.toWei(amountApprove), account, handleDepositResponse);
        } catch (e) {
            handleDepositResponse(null, e);
        }
    }

    const handleDepositResponse = (receipt, error = null) => {
        let amountApprove = String(amount).toString().replace(/,/g, '');
        const log = {
            date: moment().valueOf(),
            type: `Deposit ${amountApprove} MWAR`,
            amount,
            chainId,
            account,
        };

        // ERROR
        if (error) {
            // TIMEOUT
            if (error?.code === ERROR_CODE_TIME_OUT) {
                notification.error({
                    message: 'Stake timeout',
                    description: error?.message
                });
                setIsLoading(false);
                setStep(STEP.AMOUNT);
                return;
            }

            // FAILED
            const description = error?.message ?? '';
            if (error?.receipt?.transactionHash) {
                log.isError = true;
                log.transactionHash = error?.receipt?.transactionHash;
                dispatch(storeTransactionLog(log));
                notification.error({
                    message: 'Stake failed',
                    description
                });
                setStep(STEP.AMOUNT);
                setIsLoading(false);
                return;
            }

            // Reject
            notification.error({
                message: 'Stake failed'
            });
            setStep(STEP.AMOUNT);
            setIsLoading(false);
            return;
        }


        // SUCCESS
        if (receipt && receipt?.transactionHash) {
            dispatch(setAttackTeam(nationItem));
            dispatch(isAttacking(true));
            dispatch(refeshBalance());
            dispatch(getDataTeamInfoAndupdate());
            dispatch(getDataUserInfoAndUpdate());

            log.isError = false;
            log.transactionHash = receipt?.transactionHash;
            dispatch(storeTransactionLog(log));

            setIsLoading(false);
            setIsSuccess(true);
        }
    }

    /**
     * CLAIM REWARD
     * @param {*} stepInput 
     */
    const handleClaimReward = (stepInput) => {
        setStep(stepInput);
        setIsLoading(true);
        const presenterContract = new window.web3.eth.Contract(PresenterABI, addresses['PRESENTER']);
        const teamIndex = getTeamIndex(nationItem);

        try {
            claimReward(presenterContract, teamIndex, account, handleClaimRewardResponse);
        } catch (e) {
            handleClaimRewardResponse(null, e);
        }
    }

    const handleClaimRewardResponse = (receipt, error = null) => {
        const log = {
            date: moment().valueOf(),
            type: `Claim Reward ${nationItem} team`,
            amount,
            chainId,
            account,
        };

        // ERROR
        if (error) {
            // TIMEOUT
            if (error?.code === ERROR_CODE_TIME_OUT) {
                notification.error({
                    message: 'Claim Reward timeout',
                    description: error?.message
                });
                setIsLoading(false);
                setStep(STEP.NATION);
                return;
            }

            // FAILED
            if (error?.receipt?.transactionHash) {
                log.isError = true;
                log.transactionHash = error?.receipt?.transactionHash;
                dispatch(storeTransactionLog(log));
                notification.error({
                    message: 'Claim Reward failed',
                    description: error?.message
                });
                setIsLoading(false);
                setStep(STEP.NATION);
                return;
            }

            // Reject
            notification.error({
                message: 'Claim Reward failed'
            });
            setIsLoading(false);
            setStep(STEP.NATION);
            return;
        }

        // SUCCESS
        if (receipt && receipt?.transactionHash) {
            dispatch(refeshBalance());
            dispatch(getDataTeamInfoAndupdate());

            log.isError = false;
            log.transactionHash = receipt?.transactionHash;
            dispatch(storeTransactionLog(log));

            setIsLoading(false);
            setIsSuccess(true);
        }
    }


    const handleSwitch = (option) => {
        resetDataSelect();
        updateSelectedOption(option);
    };

    const onClose = () => {
        setStep(STEP.NATION);
        resetDataSelect();
        handleClose();
    };


    const resetDataSelect = () => {
        setIsLoading(false);
        setIsSuccess(false);
        setStep(STEP.NATION)
        setNationTeam(null);
        setNationItem(null);
        setPoolItem(null);
        setAmount(0);
        setIsShowErrorBalance(false);
        setAvatar(coinImage);
        updateSelectedOption(TAB_STAKE_POP_UP[0]);
    }

    const renderBody = () => {
        if (isMyStake(selectedOption)) {
            return <div className={style.rowContainer}>
                <RadioGroup items={nationList} value={nationItem} />
            </div>
        }
        return <RadioGroup items={isReward(selectedOption) ? nationListReward : nationList} value={nationItem} onChange={(evt) => {
            setNationItem(evt.target.value);
            if (evt.target.value === TEAMS.DOGE.CODE) {
                setNationTeam(teamInfo.doge);
            } else if (evt.target.value === TEAMS.PEPE.CODE) {
                setNationTeam(teamInfo.pepe);
            } else if (evt.target.value === TEAMS.WOJAK.CODE) {
                setNationTeam(teamInfo.wojak);
            }
        }} />
    }

    const renderButton = () => {
        return <div className={style.buttonGroup}>
            <Button
                wrapperClass={style.button}
                text={'CLOSE'}
                disabled={isLoading}
                primary
                gray
                onClick={onClose}
            />
            {isNewStake(selectedOption) &&
                <Button
                    wrapperClass={style.button}
                    text={'NEXT'}
                    disabled={!nationItem || !isStakingDay(stageIndex)}
                    primary
                    green
                    onClick={() => handleStep(STEP.POOL)}
                />
            }
            {isReward(selectedOption) &&
                <Button
                    wrapperClass={style.button}
                    text={'CLAIM'}
                    disabled={isLoading || isRewardZero(nationTeam) || !isStakingDay(stageIndex)}
                    primary
                    green
                    onClick={() => handleClaimReward(STEP.CLAIMING)}
                />
            }
        </div>
    }

    const isShowLoadingStake = (stepInput, isSuccessInput, isLoadingInput) => {
        return stepInput === STEP.STAKING && !isSuccessInput && isLoadingInput;
    }

    const isShowLoadingClaim = (stepInput, isSuccessInput, isLoadingInput) => {
        return stepInput === STEP.CLAIMING && !isSuccessInput && isLoadingInput;
    }

    return (
        <Modal
            wrapperClass={style.container}
            simple
            width={'390rem'}
            closeable={(isMobile || isTablet) && (isShowLoadingStake(step, isSuccess, isLoading) || isShowLoadingClaim(step, isSuccess, isLoading))}
            maskClosable={false}
            visible={visible}
            handleCancel={() => {
                onClose();
                handleCancel();
            }}
        >
            {step === STEP.NATION &&
                <div>
                    <div className={style.header}>
                        <img className={style.avatar} src={avatar} alt='avatar' />
                        <h2 className={style.heading}>
                            Choose Team to Stake In
                        </h2>
                        <div className={style.description}>
                            Stakes are permanent, and your tokens will be burned in return for your permanent stake representation in the game.
                        </div>
                    </div>
                    <Switch
                        options={TAB_STAKE_POP_UP}
                        selectedOption={selectedOption}
                        onChange={handleSwitch}
                    />

                    {renderBody()}

                    {renderButton()}

                </div>
            }
            {step === STEP.POOL &&
                <div>
                    <div className={style.header}>
                        <img className={style.avatar} src={avatar} alt='avatar' />
                        <h2 className={style.heading}>
                            Choose Sub-Pool to Stake In
                        </h2>
                        <div className={style.description}>
                            You must choose which area of your team to stake in.
                        </div>
                    </div>
                    <div>
                        <RadioGroup items={poolList} value={poolItem} onChange={(evt) => {
                            setPoolItem(evt.target.value);
                        }} />
                    </div>
                    <div className={style.buttonGroup}>
                        <Button
                            wrapperClass={style.button}
                            text={'BACK'}
                            primary
                            gray
                            onClick={() => handleStep(STEP.NATION)}
                        />
                        <Button
                            wrapperClass={style.button}
                            text={'NEXT'}
                            primary
                            green
                            disabled={!poolItem || !isStakingDay(stageIndex)}
                            onClick={() => handleStep(STEP.AMOUNT)}
                        />
                    </div>
                </div>
            }
            {step === STEP.AMOUNT &&
                <div>
                    <div className={style.header}>
                        <img className={style.avatar} src={avatar} alt='avatar' />
                        <h2 className={style.heading}>
                            Enter Stake Amount
                        </h2>
                    </div>
                    <div>
                        <StakeAmount balance={tokenBalance} amount={amount}
                            onChangeAmount={(newAmount) => {
                                if (!newAmount) {
                                    setAmount(0);
                                    return;
                                }
                                if (tokenBalance > parseInt(newAmount)) {
                                    setAmount(formatNumber(newAmount.toString().replace(/,/g, '')));
                                    setIsShowErrorBalance(false);
                                } else {
                                    setAmount(formatNumber(newAmount.toString().replace(/,/g, '')));
                                    setIsShowErrorBalance(true);
                                }
                            }} />
                    </div>
                    {isShowErrorBalance && <span className={style.errMessage}>
                        You will need to buy MWAR to stake in this pool!
                    </span>}
                    <div className={style.buttonGroup}>
                        <Button
                            wrapperClass={style.button}
                            text={'BACK'}
                            disabled={isLoading}
                            primary
                            gray
                            onClick={() => handleStep(STEP.POOL)}
                        />
                        <Button
                            wrapperClass={style.button}
                            text={'STAKE'}
                            disabled={isLoading || !isStakingDay(stageIndex) || isShowErrorBalance || amount === 0 || amount === '0'}
                            primary
                            green
                            onClick={() => handleStep(STEP.STAKING)}
                        />
                        {isShowErrorBalance && <Button
                            wrapperClass={style.button}
                            text={'Buy MWAR'}
                            primary
                            blue
                            onClick={() => window.open(LINK_BUY, '_blank')} />
                        }
                    </div>
                </div>
            }

            {/* LOADING - SUCCESS POP - UP */}
            {isShowLoadingStake(step, isSuccess, isLoading) &&
                <div>
                    <div className={style.header}>
                        <div id={ID_LOADING_STAKE} className={style.loadingStaking} />
                        <h2 className={style.heading}>
                            STAKING COINS ...
                        </h2>
                    </div>
                </div>
            }

            {step === STEP.STAKING && isSuccess && !isLoading &&
                <div>
                    <div className={style.successContainer}>
                        <div className={style.imageCoin}></div>
                        <h2 className={style.heading}>
                            Staking Coin Success!
                        </h2>
                        <Button
                            wrapperClass={style.button}
                            text={'CLOSE'}
                            primary
                            green
                            onClick={onClose}
                        />
                    </div>
                </div>
            }

            {isShowLoadingClaim(step, isSuccess, isLoading) &&
                <div>
                    <div className={style.header}>
                        <div id={ID_LOADING_STAKE} className={style.loadingStaking} />
                        <h2 className={style.heading}>
                            CLAIM REWARD ...
                        </h2>
                    </div>
                </div>
            }

            {step === STEP.CLAIMING && isSuccess && !isLoading &&
                <div>
                    <div className={style.successContainer}>
                        <div className={style.imageCoin}></div>
                        <h2 className={style.heading}>
                            Claim Reward Success!
                        </h2>
                        <Button
                            wrapperClass={style.button}
                            text={'CLOSE'}
                            primary
                            green
                            onClick={onClose}
                        />
                    </div>
                </div>
            }
        </Modal>
    );
}
