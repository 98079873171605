import React, { useEffect, useState } from 'react';
import PageLayout from '../../components/PageLayout';
import SectionOne from './SectionOne';
import SectionTwo from './SectionTwo';
import SectionThree from './SectionThree';
import SectionFour from './SectionFour';
import SectionFive from './SectionFive';
import { getQueryParam } from '../../utils';
import { useDispatch } from 'react-redux';
import { checkVerifyEmail } from '../../store/modules/User/actions';
import Modal from '../../components/Modal';
import style from '../../components/TermsModal/TermsOfServiceModal.module.scss';
import Button from '../../components/Button';

export default function Home() {
    const dispatch = useDispatch();

    const [isVisibleModal, setIsVisibleModal] = useState(false);
    const [title, setTitle] = useState('');
    const [subTitle, setSubTitle] = useState('');

    useEffect(() => {
        const email = getQueryParam('verify');
        const code = getQueryParam('code');
        const address = getQueryParam('address');
        if (email && code && address) {
            const body = {
                email,
                code,
                address
            };
            dispatch(checkVerifyEmail(body))
                .then(res => {
                    const messageText = res?.data?.message;
                    if (messageText) {
                        setTitle('Verify Email Address');
                        setSubTitle(messageText);
                        setIsVisibleModal(true);
                    } else {
                        setTitle('Verify Email Address');
                        setSubTitle(`Your email ${email} has been verified`);
                        setIsVisibleModal(true);
                    }
                });
        }
    }, [dispatch])

    const closeModal = () => setIsVisibleModal(false);

    return (
        <PageLayout>
            <SectionOne/>
            <SectionTwo/>
            <SectionThree/>
            <SectionFour/>
            <SectionFive/>
            {
                isVisibleModal && (
                    <Modal
                        wrapperClass={style.container}
                        closable={false}
                        simple
                        visible={isVisibleModal}
                    >
                        <h2 className={style.heading}>
                            {title}
                        </h2>
                        <p className={style.text}>
                            {subTitle}
                        </p>
                        <Button
                            wrapperClass={style.button}
                            text={'Close'}
                            primary
                            green
                            onClick={closeModal}
                        />
                    </Modal>
                )
            }

        </PageLayout>
    )
}
