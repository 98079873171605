import React from 'react';
import VoteModal from './VoteModal';

export default function VoteModalContainer(props) {
    const {visible, isStakedWarTeam} = props;
    return (
        <VoteModal
            visible={visible}
            isStakedWarTeam={isStakedWarTeam}
            {...props}
        />
    );
}
